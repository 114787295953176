.paymentWrapperComponent {
    .customContainer {
        padding: 0 10rem;
        width: 100%;
        overflow: hidden;

        @media screen and (max-width:600px) {
            padding: 2rem;
        }


        .customCard {
            padding: 0;
            border-radius: 8px;

            .ant-card-body {
                padding: 0;

                .LeftSection {
                    background: #eb2a2e;
                    border-radius: 8px;

                    .amountSection {
                        padding: 2rem 2rem 1rem 2rem;

                        @media screen and (max-width:1600px) {
                            padding: 1rem 2rem 0.5rem 2rem;
                        }

                        h5 {
                            color: white;
                            font-weight: bold;

                            @media screen and (max-width:1600px) {
                                font-size: 1rem;
                                margin-bottom: 0;
                            }
                        }

                        input {
                            border: none;
                            border-radius: 5px;
                            padding: 0.5rem;
                            height: auto;
                            width: 100%;
                            margin: 0.5rem 0;

                            @media screen and (max-width:1600px) {
                                padding: 0.3rem;
                                margin: 0.3rem 0;
                            }

                            &:focus-visible {
                                outline: none;
                                box-shadow: none;
                            }
                        }

                        .tipInfo {
                            display: flex;
                            color: white;
                            align-items: center;
                            margin-top: 0.5rem;

                            @media screen and (max-width:1600px) {
                                margin-top: 0.3rem;
                            }

                            p {
                                margin-bottom: 0;
                                padding-left: 0.5rem;

                                @media screen and (max-width:1600px) {
                                    padding-left: 0.3rem;
                                    font-size: 12px;
                                }
                            }
                        }
                    }

                    .tipSection {
                        padding: 1rem 2rem;

                        @media screen and (max-width:1600px) {
                            padding: 0.5rem 2rem;
                        }

                        h5 {
                            color: white;
                            font-weight: bold;

                            @media screen and (max-width:1600px) {
                                font-size: 1rem;
                                margin-bottom: 0;
                            }
                        }

                        input {
                            border: none;
                            border-radius: 5px;
                            padding: 0.5rem;
                            height: auto;
                            width: 100%;
                            margin: 0.5rem 0;

                            @media screen and (max-width:1600px) {
                                padding: 0.3rem;
                                margin: 0.3rem 0;
                            }

                            &:focus-visible {
                                outline: none;
                                box-shadow: none;
                            }
                        }

                        .tipInfo {
                            display: flex;
                            color: white;
                            align-items: center;
                            margin-top: 0.5rem;

                            @media screen and (max-width:1600px) {
                                margin-top: 0.3rem;
                            }

                            p {
                                margin-bottom: 0;
                                padding-left: 0.5rem;

                                @media screen and (max-width:1600px) {
                                    padding-left: 0.3rem;
                                    font-size: 12px;
                                }
                            }
                        }
                    }

                    .Message {
                        padding: 1rem 2rem;
                        width: 100%;

                        @media screen and (max-width:1600px) {
                            padding: 0.5rem 2rem;
                        }

                        h5 {
                            color: white;
                            font-weight: bold;

                            @media screen and (max-width:1600px) {
                                font-size: 1rem;
                                margin-bottom: 0;
                            }
                        }

                        textarea {
                            width: 100%;
                            border-radius: 8px;
                            padding: 1rem;
                        }
                    }

                    .serviceSection {
                        padding: 0rem 2rem 1rem 2rem;

                        @media screen and (max-width:1600px) {
                            padding: 0rem 2rem 0.5rem 2rem;
                        }

                        h5 {
                            color: white;
                            font-weight: bold;

                            @media screen and (max-width:1600px) {
                                font-size: 1rem;
                            }
                        }

                        input {
                            border: none;
                            border-radius: 5px;
                            padding: 0.5rem;
                            height: auto;
                            width: 100%;
                            margin: 0.5rem 0;

                            @media screen and (max-width:1600px) {
                                padding: 0.3rem;
                                margin: 0.3rem 0;
                            }

                            &:focus-visible {
                                outline: none;
                                box-shadow: none;
                            }
                        }
                    }
                }

                .rightSection {
                    .paymentFormSection {
                        padding: 1rem 5rem;

                        @media screen and (max-width:600px) {
                            padding: 1rem 2rem;
                        }

                        @media screen and (max-width:1600px) {
                            padding: 0rem 2rem;
                        }
                    }

                    .OrSection {
                        position: relative;

                        span {
                            position: absolute;
                            z-index: 999;
                            top: 5px;
                            left: 46%;
                            background: white;
                            padding: 0 0.6rem;
                        }
                    }

                    .cardWrapper {
                        padding: 1rem 5rem;

                        @media screen and (max-width:600px) {
                            padding: 1rem 2rem;
                        }

                        @media screen and (max-width:1600px) {
                            padding: 1rem 4rem;
                        }

                        .StripeHeader {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            @media screen and (max-width:1600px) {
                                font-size: 1rem;
                            }
                        }

                        .totalAmountRow {
                            .totalAmountSection {
                                display: flex;
                                border: 1px solid #cecece;
                                height: 2.57rem;
                                width: 100%;
                                border-radius: 8px;
                                padding: 0.4rem;
                                align-items: center;
                                color: #cecece;

                                @media screen and (max-width:1600px) {
                                    height: 2.57rem;
                                }

                            }

                            .totalAmountSection {
                                color: black;
                            }

                            .infoCol {
                                display: flex;
                                align-items: center;
                                padding: 0.5rem 0 0 0.3rem;
                                color: #eb2a2e;

                                svg {
                                    margin-right: 0.5rem;
                                }
                            }
                        }
                    }

                    .bottomBtnContainer {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 1.5rem;

                        .paymentBtn {
                            background: #eb2a2e;
                            border: none;
                            border-radius: 25px;
                            width: 10rem;

                            @media screen and (max-width:1600px) {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }

        .bottomWrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 1rem;
            font-size: 1rem;

            span {
                font-weight: bold;
            }
        }
    }
}