.mobileLoginWrapper {
  padding: 1.5rem;
  // height: 100vh;
  background: url("../../Images/LoginBackground.jpg");
  background-size: cover;
  background-position-x: 50%;
  color: white;
  height: 100%;
  min-height: 100vh;

  .ant-card {
    margin: 8rem auto;

    @media screen and (max-width: 480px) {
      margin: 8rem auto;
    }

    @media screen and (max-width: 380px) {
      margin: 5rem auto;
    }

    .modalHeader {
      display: flex;
      justify-content: flex-end;

      button {
        cursor: pointer;
        position: absolute;
        right: -05px;
        top: -20px;
        padding: 0.5rem 0.9rem;
        border-radius: 10px;
        border: none;
        background: #eb2a2e;
        color: #fff;
        font-size: 20px;
        height: auto;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        @media screen and (max-width: 500px) {
          font-size: 18px;
          right: -15px;
          padding: 0.3rem 0.7rem;
        }
      }
    }

    .ant-card-body {
      height: 100%;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

      @media screen and (max-width: 500px) {
        padding: 0;
        border-radius: 0;
      }

      .logoSection {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 0;

        @media screen and (max-width: 500px) {
          padding: 2rem 0 0 0;
        }

        .ant-col {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .ant-form {
        @media screen and (max-width: 500px) {
          padding: 0.5rem 2rem 1rem 2rem;
        }

        .ant-form-item {
          margin-bottom: 12px;
        }

        .inputLabel {
          padding: 0.4rem 0;
          font-size: 15px;
          font-weight: 500;
          text-transform: capitalize;
        }

        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .ant-input {
              padding: 0.6rem 0;
              border: none;
              border-bottom: 1px solid #cecece;
              border-radius: 0;

              &:focus-visible {
                outline: none;
              }

              &:focus {
                box-shadow: none;
              }
            }
          }
        }

        .passwordFormItem {
          margin-bottom: 0;

          .passwordInput {
            padding: 0.02rem 0rem 0.02rem 0rem;
            border: none;
            border-radius: 0;

            .ant-input-suffix {
              border-bottom: 1px solid #cecece;
              margin-inline-start: 0;
            }

            &:focus {
              box-shadow: none;
            }
          }
        }

        .forgotPwdSection {
          .ant-col {
            display: flex;
            justify-content: flex-end;
            padding: 0 0 1rem 0;

            a {
              font-size: 12px;
              padding: 0.6rem 0;
              color: #eb2a2e;
            }
          }
        }
      }

      .buttonSection {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;

        button {
          background: #eb2a2e;
          padding: 0.4rem 1.8rem;
          height: auto;
          border-radius: 25px;

          &:hover {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            transform: translateY(-0.25em);
          }
        }
      }

      .registerSection {
        .ant-col {
          display: flex;
          justify-content: center;
          align-items: center;

          .LoginbtnWrapper {
            margin-bottom: 2rem;

            a {
              .login-with-google-btn {
                transition: background-color .3s, box-shadow .3s;

                padding: 12px 16px 12px 42px;
                border: none;
                border-radius: 3px;
                box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
                color: #757575;
                font-size: 14px;
                font-weight: 500;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
                background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
                background-color: white;
                background-repeat: no-repeat;
                background-position: 12px 14.5px;

                &:hover {
                  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
                }

                &:active {
                  background-color: #eeeeee;
                }

                &:focus {
                  outline: none;
                  box-shadow:
                    0 -1px 0 rgba(0, 0, 0, .04),
                    0 2px 4px rgba(0, 0, 0, .25),
                    0 0 0 3px #c8dafc;
                }

              }
            }
          }

          .registerText {
            font-weight: 700;
            display: flex;
            margin-bottom: 0.5rem;

            a {
              font-size: 30px;
            }

            p {
              color: #eb2a2e;
              font-size: 14px;
              font-weight: 700;
              text-decoration: underline;
              margin-left: 0.4rem;
            }
          }
        }
      }
    }
  }
}

.reverifymodal {
  .ant-modal-content {
    padding: 0;

    .ant-modal-body {
      padding: 20px 24px;

      .modalHeader {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-bottom: 1px solid #cecece;
        padding-bottom: 0.6rem;

        .backIcon {
          position: absolute;
          left: 0;
          font-size: 25px;
          color: black;
          cursor: pointer;
        }

        h4 {
          font-size: 24px;

          @media screen and (max-width: 500px) {
            font-size: 20px;
          }
        }

        button {
          position: absolute;
          right: -40px;
          top: -35px;
          padding: 0.5rem 0.9rem;
          border-radius: 10px;
          border: none;
          background: #eb2a2e;
          color: #fff;
          font-size: 20px;
          height: auto;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

          @media screen and (max-width: 450px) {
            right: -30px;
          }

          &:hover {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            transform: translateY(-0.15em);
          }
        }
      }

      .modal-body {
        padding: 2rem 0;

      }
    }
  }
}