.customer-profile {
  background-color: #eb2e2e;
  .contentRow {
    background-color: #eb2e2e;
    height: auto !important;
    min-height: 100vh;
    height: 100%;
    padding-top: 7rem;

    .formSection {
      padding: 2rem 5rem;
      @media screen and (max-width: 1024px) {
        padding: 3rem 5rem;
      }
      @media screen and (max-width: 500px) {
        padding: 1rem 1.5rem;
        margin: 2rem auto;
      }
      @media screen and (min-width: 1366px) {
        padding: 5rem;
      }
      .ant-card {
        border: none;
        .ant-card-body {
          position: relative;
          padding: 1rem 0;
          text-align: center;
          width: 100%;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          .header {
            display: flex;
            justify-content: center;
            position: relative;

            .imgSection {
              width: 100px;
              height: 100px;
              border-radius: 50%;
              border: 5px solid #000;
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden;
              position: relative;
              bottom: 3rem;
              background: #fff;
              // padding: 0.5rem;
              .ant-image {
                width: 100%;
                height: 90px;
                img {
                  height: 100px;
                  object-fit: cover;
                }
              }
            }
          }

          .informationContent {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            flex-direction: column;
            width: 100%;

            .nameSection {
              padding: 1rem 0;
            }

            .ant-col {
              width: 100%;

              .ant-tabs {
                .ant-tabs-nav {
                  &::before {
                    border-bottom: none;
                  }
                  .ant-tabs-nav-wrap {
                    padding: 0 1rem;
                    .ant-tabs-nav-list {
                      width: 100%;
                      display: flex;
                      justify-content: space-evenly;
                      .ant-tabs-tab {
                        padding: 0.6rem 0rem;
                        font-size: 14px;
                        &:hover {
                          color: #eb2e2e !important;
                        }
                        @media screen and (min-width: 768px) {
                          width: 33%;
                          text-align: center;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          font-size: 18px;
                          font-weight: 500;
                        }
                      }
                      .ant-tabs-tab-active {
                        @media screen and (min-width: 768px) {
                          padding: 0.5rem 3rem;
                          background: #eb2e2e;
                          color: white !important;
                          border-radius: 25px;
                        }

                        .ant-tabs-tab-btn {
                          color: #eb2e2e;

                          @media screen and (min-width: 768px) {
                            color: white;
                          }
                        }
                      }
                      .ant-tabs-ink-bar {
                        height: 2px;
                        background: red !important;

                        @media screen and (min-width: 768px) {
                          height: 0px;
                        }
                      }
                    }
                  }
                }
              }

                .infoMsg {
                  p {
                    display: flex;
                    align-items: center;
                    margin: 0.5rem 0;
        
                    @media only screen and (max-width: 767px) {
                      align-items: baseline;
                    }
        
                    svg {
                      margin-right: 0.5rem;
                      color: #eb2a2e;
                    }
                  }
                }
              
            }
          }
        }
      }
    }
  }
}
