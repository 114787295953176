.sidebarWrapper {
  .navbarWrapper {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 2rem;
    background: black;
    align-items: center;
    position: fixed;
    width: 100%;
    margin-bottom: 0rem;
    top: 0;
    z-index: 99;

    .drawerBtn {
      background: #eb2a2e;
      font-size: 29px;
      font-weight: 700;
      display: flex;
      height: 2.6rem;
      align-items: center;
      justify-content: center;
      padding: 22px 12px;

      @media screen and (max-width: 500px) {
        font-size: 22px;
        height: 2.2rem;
        padding: 8px 8px;
      }
    }

    .headerbtn {
      background: transparent;
      border: none;
      cursor: pointer;
    }

    h4 {
      color: white;
      font-size: 28px;

      @media screen and (max-width: 500px) {
        font-size: 22px;
      }

      a {
        color: white;
      }
    }
  }
}

.drawerWrapper {
  .ant-drawer-wrapper-body {
    background: black;

    .ant-drawer-body {
      text-align: left;
      padding: 5rem 0 1rem 0;

      .drawermenu {
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;

        .imgRow {
          border-bottom: 1px solid #cecece;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 4rem;

          .logoSection {
            width: 100px;

            @media screen and (max-width: 500px) {
              width: 60px;
            }
          }
        }

        .drawerSubMenu {
          .mobileMenu {
            background: black;
            color: white;
            display: flex;
            flex-direction: column;
            padding: 2rem 0rem;

            @media screen and (max-width: 500px) {
              padding: 1rem 0rem;
            }

            li {
              margin: 1.3rem 0;

              @media screen and (max-width: 500px) {
                margin: 0.4rem 0;
              }

              svg {
                margin-right: 0.8rem;
                font-size: 26px;

                @media screen and (max-width: 500px) {
                  font-size: 20px;
                }
              }

              .ant-menu-title-content {
                font-size: 20px;

                @media screen and (max-width: 500px) {
                  font-size: 14px;
                }
              }
            }

            .ant-menu-item-selected {
              background: #eb2a2e;
              border: none;
              color: white;
            }

            .ant-menu-item {
              &:hover {
                color: white;
                background: #eb2a2e;
              }
            }
          }
        }

        .footerContent {
          position: relative;
          border: none;
          display: flex;
          align-items: center;

          .ant-col {
            border-top: 1px solid #cecece;
            padding: 1rem 1.5rem;

            a {
              font-size: 20px;

              @media screen and (max-width: 500px) {
                font-size: 14px;
              }

              color: white;
              text-decoration: none;

              svg {
                font-size: 30px;
                font-weight: 700;
                margin-right: 0.7rem;

                @media screen and (max-width: 500px) {
                  font-size: 20px;
                }
              }
            }

            .logOutBtn {
              background: transparent;
              color: white;
              border: none;
              font-size: 20px;
              align-items: center;
              display: flex;
              width: 100%;
              justify-content: flex-start;
              padding: 4px 2px;

              @media screen and (max-width: 500px) {
                font-size: 14px;
              }

              svg {
                font-size: 28px;
                margin-right: 1.25rem;
                color: white;

                @media screen and (max-width: 500px) {
                  font-size: 22px;
                }
              }
            }
          }
        }
      }
    }
  }
}