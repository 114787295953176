.customer-payment {
  background-color: black;
  padding-top: 6rem;

  .contentRow {
    background-color: #eb2a2e;
    height: 100%;
    min-height: 100vh;
    padding-top: 1rem;

    .tableSection {
      padding: 2rem 5rem;

      @media screen and (max-width: 500px) {
        padding: 1rem;
      }

      .header {
        display: flex;
        justify-content: center;

        h4 {
          font-size: 24px;
          font-weight: 600;

          @media screen and (max-width: 500px) {
            font-size: 20px;
          }
        }
      }

      .filterSearchWrapper {
        margin-bottom: 1rem;

        .filterSection {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          button {
            background: #eb2a2e;
            color: white;
            border: none;
            box-shadow: rgba(47, 22, 22, 0.1) 0px 1px 1px 0px inset,
              rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
              rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
          }
        }

        .searchSection {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          right: 3rem;

          @media screen and (max-width: 1000px) {
            right: 0;
          }

          span {
            display: flex;
            justify-content: flex-end;

            &:focus {
              box-shadow: none;
              outline: none;
            }

            &:focus-visible {
              box-shadow: none;
              outline: none;
            }
          }

          .formControl {
            border: none;
          }

          .ant-input-suffix {
            border-bottom: 1px solid #eb2a2e;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-inline-start: 0;
          }

          input {
            border-radius: 0;
            border: none;
            border-bottom: 1px solid #eb2a2e;
            padding: 4.5px 11px;
            width: 350px;

            @media screen and (max-width: 1000px) {
              width: auto;
            }
          }

          .ant-input-group-addon {
            button {
              border: none;
              border-bottom: 1px solid #eb2a2e;
              border-radius: 0;
              color: #000 !important;
              font-weight: 700;
              background: #fff;
            }
          }
        }
      }

      .ant-card {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        .ant-card-body {
          @media screen and (max-width: 500px) {
            padding: 24px 10px;
          }

          .tableWrapper {
            .ant-table-wrapper {
              .ant-table {
                .ant-table-container {
                  .ant-table-content {
                    overflow: hidden;
                    overflow-x: scroll;

                    @media screen and (max-width: 500px) {
                      overflow: hidden;
                      overflow-x: scroll;
                    }

                    .ant-table-thead {
                      .ant-table-cell {
                        text-align: center;
                        color: #67748e;

                        @media screen and (max-width: 500px) {
                          font-size: 12px;
                          white-space: nowrap;
                        }
                      }
                    }

                    .ant-table-tbody {
                      .ant-table-cell {
                        text-align: center;
                        color: #67748e;

                        @media screen and (max-width: 500px) {
                          font-size: 12px;
                          white-space: nowrap;
                        }

                        .viewDetailsButton {
                          background: #eb2a2e;
                          color: white;
                          border: none;
                          border-radius: 25px;
                          padding: 4px 15px;

                          @media screen and (max-width: 500px) {
                            font-size: 14px;
                            padding: 0.1rem 0.8rem;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.detailModal {
  // // margin: 0rem auto;

  // @media screen and (max-width: 500px) {
  //   margin: 0rem auto;
  // }
  // @media screen and (min-width: 1050px) and (max-width:1400px) {
  //   margin: 0rem auto;
  // }

  .ant-modal-body {
    .modalHeader {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding-bottom: 0.6rem;
      border-bottom: 1px solid #cecece;

      h4 {
        font-size: 24px;

        @media screen and (max-width: 500px) {
          font-size: 16px;
        }
      }

      button {
        position: absolute;
        right: -40px;
        top: -35px;
        padding: 0.5rem 0.9rem;
        border-radius: 10px;
        border: none;
        background: #eb2a2e;
        color: #fff;
        font-size: 20px;
        height: auto;

        @media screen and (max-width: 500px) {
          font-size: 16px;
          right: -35px;
        }
      }
    }

    .inputLabel {
      color: #000;
      font-weight: 500;
      font-size: 18px;
      padding: 0.8rem 0 0.5rem 0;

      @media screen and (max-width: 500px) {
        font-size: 14px;
      }
    }

    .ant-input {
      padding: 0.6rem;
    }

    .buttonSection {
      display: flex;
      justify-content: center;
      padding: 1rem;

      .btn {
        background: #eb2a2e;
        color: white;
        border-radius: 25px;
        padding: 0.5rem 2rem;
        height: auto;
        margin-right: 1rem;

        @media screen and (max-width: 500px) {
          padding: 0.3rem 1rem;
          font-size: 14px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.ReciptModal {
  width: auto !important;
  padding: 0rem;

  .firstRow {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;

    p {
      margin-bottom: 0;
    }

    h4 {
      font-size: 16px;
    }

    .imgCol {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .imgSection {
        width: 150px;
      }
    }
  }

  .secondRow {
    padding: 1rem 0;

    h4 {
      color: #202e5a;
      font-size: 35px;
    }
  }

  .thirdRow {
    padding: 1rem 0;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .leftColumn {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;

      h4 {
        font-size: 16px;
      }

      p {
        margin-bottom: 0;
      }
    }

    .rightColumn {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .rightSubColLeft {
        display: flex;
        justify-content: flex-end;
      }

      .rightSubColRight {
        display: flex;
        justify-content: flex-start;
      }

      .flexRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        h4 {
          font-size: 16px;
          display: flex;
          align-items: center;
        }

        span {
          display: flex;
          align-items: center;
          margin: 0 0 0.3rem 0.3rem;
        }
      }
    }
  }

  .fourthRow {
    padding: 1rem 0;
    border-bottom: 1px dashed #cecece;

    .ant-table-wrapper {
      .ant-table {
        .ant-table-content {
          .ant-table-thead {
            .ant-table-cell {
              background: #d2d5de;
              color: #202e5a;
              border-radius: 0;
            }
          }

          .ant-table-tbody {
            .ant-table-cell {
              font-weight: 700;
              border-bottom: 0;
            }
          }
        }
      }
    }
  }

  .fifthRow {
    padding: 1rem 0;

    h4 {
      font-size: 16px;
      margin: 0.2rem 0;
    }

    .lastCol {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      h4 {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .bankDetailRow {
    margin-top: 10rem;

    h4 {
      font-size: 16px;
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.invoiceModal {
  padding: 2rem 0.5rem;

  .infoRow {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;

    p {
      margin-bottom: 0;
    }

    h4 {
      font-size: 16px;
    }

    .imgCol {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .imgSection {
        width: 150px;
      }
    }
  }

  .headingRow {
    padding: 0;

    h4 {
      color: #202e5a;
      font-size: 35px;
    }
  }

  .billingRow {
    padding: 1rem 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #202e5a;

    .leftColumn {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;

      h4 {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 700;
      }

      p {
        margin-bottom: 0;
      }
    }

    .rightColumn {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .rightSubColLeft {
        display: flex;
        justify-content: flex-end;
      }

      .rightSubColRight {
        display: flex;
        justify-content: flex-start;
      }

      .flexRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        .leftHeader {
          display: flex;
          justify-content: flex-end;

          h4 {
            font-size: 16px;
            display: flex;
            align-items: center;
            font-weight: 700;
          }
        }

        .rightValue {
          display: flex;
          justify-content: flex-start;

          span {
            display: flex;
            align-items: center;
            margin: 0 0 0.3rem 0.3rem;
          }
        }
      }
    }
  }

  .purchaseRow {
    padding: 1rem 0;

    h4 {
      font-size: 16px;
      font-weight: 700;
      display: flex;
      justify-content: flex-start;
      text-transform: uppercase;
    }
  }

  .tableRow {
    padding: 1rem 0;
    border-bottom: 1px dashed #cecece;

    .ant-table-wrapper {
      .ant-table {
        .ant-table-content {
          .ant-table-thead {
            .ant-table-cell {
              background: #d2d5de;
              color: #202e5a;
              border-radius: 0;
              text-align: center;
              padding: 10px 16px;
            }
          }

          .ant-table-tbody {
            .ant-table-cell {
              font-weight: 700;
              border: 0;
              text-align: center;
              padding: 10px 16px;
            }
          }
        }
      }
    }
  }

  .totalAmountRow {
    width: 100%;
    padding: 1rem 2.5rem;

    .leftCol {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;

      h4 {
        margin: 0;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 700;
        display: flex;
        justify-content: flex-end;
      }
    }

    .rightCol {
      h4 {
        margin-bottom: 0;
        font-size: 16px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0;
      }

      .amount {
        font-weight: 900;
      }
    }
  }

  .VatRow {
    h4 {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 700;
    }

    .ant-table-wrapper {
      .ant-table {
        .ant-table-content {
          .ant-table-thead {
            .ant-table-cell {
              background: #d2d5de;
              color: #202e5a;
              border-radius: 0;
              text-align: center;
              padding: 10px 16px;
            }
          }

          .ant-table-tbody {
            .ant-table-cell {
              font-weight: 700;
              border: 0;
              text-align: center;
              padding: 10px 16px;
            }
          }
        }
      }
    }
  }

  .bankDetailRow {
    margin-top: 10rem;

    h4 {
      font-size: 16px;
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.ant-pagination-item-active {
  background: #eb2a2e !important;
  color: white;
  border: none !important;
}

.DatepickerWrapper {
  display: flex;
  flex-direction: column;

  .StartWrapper {
    margin-top: 0.7rem;

    h4 {
      font-size: 0.9rem;
    }
  }

  .endWrapper {
    margin-top: 1rem;

    h4 {
      font-size: 0.9rem;
    }
  }
}

.resetWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    background: #eb2a2e;
    color: white;
    border: none;
    border-radius: 25px;

    &:hover {
      background-color: #eb2a2e !important;
      color: white;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      transform: translateY(-0.15em);
    }
  }
}

.paymentCard {
  display: flex;
  justify-content: flex-start !important;
  text-align: left;
  width: 100%;
  padding: 0.5rem 1.5rem;

  .addBtnSection {
    display: flex;
    justify-content: center;
    align-items: center;

    .addFormBtn {
      border: none;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: auto;
      background: #eb2a2e;
      color: white;
      border-radius: 25px;
    }
  }
}

.addNewCar {
  width: 100%;

  .formHeader {
    display: flex;
    padding: 0;
    align-items: center;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0.8rem;

    svg {
      display: flex;
      align-items: center;
      font-size: 18px;
      margin-right: 1rem;
    }

    h4 {
      font-size: 16px;
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }
  }

  .addPaymentForm {
    text-align: left;
    padding: 0.5rem 1.5rem;

    .formlabel {
      font-size: 20px;
      font-weight: 500;

      @media screen and (max-width: 500px) {
        font-size: 15px;
      }
    }

    .ant-form-item {
      margin-bottom: 10px;

      .ant-input {
        padding: 1rem;

        @media screen and (max-width: 500px) {
          padding: 0.8rem 0.8rem;
        }
      }

      .ant-select-selector {
        padding: 0.6rem 0.8rem;
        height: auto;
      }
    }

    .splitRow {
      display: flex;
      justify-content: space-between;
    }

    .customborderTop {
      border-top: 1px solid black;
      padding-top: 0.5rem;
    }

    .ant-space {
      display: flex;
      justify-content: space-between;

      .ant-space-item {
        width: 100%;
        position: relative;

        &:nth-child(2) {
          margin-left: 1rem;
        }

        .ant-form-item {
          .ant-row {
            width: 100%;
          }
        }

        .removeBtnWrapper {
          position: relative;
          padding: 0.5rem 0;

          .removeBtn {
            border: none;
            box-shadow: none;
            color: #eb2a2e;
            position: absolute;
            background: transparent;
            right: 0rem;
            bottom: 0.5rem;
            font-weight: 700;
            font-size: 14px;
          }
        }
      }
    }

    .addButtonSection {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 2rem 0;

      button {
        background: #eb2a2e;
        border-radius: 25px;
        padding: 0.5rem 2rem;
        height: auto;

        @media screen and (max-width: 500px) {
          padding: 0.3rem 1.2rem;
        }
      }
    }
  }
}

.btnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2rem;

  .downloadPDFBtn {
    background: #eb2a2e;
    color: white;
    border-radius: 25px;
    padding: 0.6rem 2rem;
    border: none;
  }
}