.customer-editprofile {
  background-color: black;

  .contentRow {
    background-color: white;
    height: 100%;

    .formSection {
      padding: 2rem 5rem;

      @media screen and (max-width: 500px) {
        padding: 1rem 1.5rem;
      }

      .header {
        display: flex;
        justify-content: center;

        h4 {
          font-size: 24px;
          font-weight: 600;

          @media screen and (max-width: 500px) {
            font-size: 20px;
          }
        }
      }

      .ant-card {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        .ant-card-body {
          .infoForm {
            .formlabel {
              font-size: 20px;
              font-weight: 500;

              @media screen and (max-width: 500px) {
                font-size: 15px;
              }
            }

            .ant-form-item {
              .ant-input {
                padding: 1rem;

                @media screen and (max-width: 500px) {
                  padding: 0.5rem;
                }
              }
            }

            .splitRow {
              display: flex;
              justify-content: space-between;
            }

            .customborderTop {
              border-top: 1px solid black;
              padding-top: 0.5rem;
            }

            .ant-space {
              display: flex;
              justify-content: space-between;

              .ant-space-item {
                width: 100%;
                position: relative;

                &:nth-child(2) {
                  margin-left: 1rem;
                }

                .ant-form-item {
                  .ant-row {
                    width: 100%;
                  }
                }

                .removeBtnWrapper {
                  position: relative;
                  padding: 0.5rem 0;

                  .removeBtn {
                    border: none;
                    box-shadow: none;
                    color: #eb2a2e;
                    position: absolute;
                    background: transparent;
                    right: 0rem;
                    bottom: 0.5rem;
                    font-weight: 700;
                    font-size: 14px;
                  }
                }
              }
            }

            .buttonSection {
              display: flex;
              justify-content: center;
              align-items: center;

              button {
                background: #eb2a2e;
                border-radius: 25px;
                padding: 0.5rem 2rem;
                height: auto;

                @media screen and (max-width: 500px) {
                  padding: 0.3rem 1.2rem;
                }
              }
            }

            .AddCarBtn {
              margin-bottom: 5px;

              .ant-row {
                .ant-form-item-control-input {
                  .ant-form-item-control-input-content {
                    display: flex;
                    justify-content: flex-end;

                    .ant-btn {
                      border: none;
                      display: flex;
                      justify-content: flex-end;
                      align-items: center;
                      width: auto;
                      background: #eb2a2e;
                      color: white;
                      border-radius: 25px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}