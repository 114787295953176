.customCard {
  display: flex;
  justify-content: flex-start !important;
  text-align: left;
  width: 100%;
  padding: 0.5rem 1.5rem;

  @media screen and (min-width: 1000px) {
    display: block;
  }

  .infoForm {
    width: 100%;

    .formHeader {
      display: flex;
      padding: 0;
      border-bottom: 1px solid #cecece;
      align-items: baseline;
      margin-bottom: 0.8rem;

      button {
        background: white;
        border: none;

        svg {
          display: flex;
          align-items: center;
          font-size: 18px;
          margin-right: 1rem;
        }
      }

      h4 {
        font-size: 20px;
        display: flex;
        align-items: center;
        padding-bottom: 0.3rem;
      }
    }

    .ant-col {
      display: flex;
      flex-direction: column;
      margin: 0.4rem 0;

      .formlabel {
        font-size: 18px;
        font-weight: 500;

        @media screen and (max-width: 500px) {
          font-size: 15px;
        }
      }

      .customSelect {
        // border: none;
        border-bottom: 1px solid #cecece;
        border-radius: 0;
        // padding: 0rem 0rem 0.375rem 0rem;

        &:focus {
          box-shadow: none;
        }

        option {
          font-size: 14px;
        }
      }

      input {
        padding: 0.8rem;
        border: none;
        border-bottom: 1px solid #cecece;

        &:focus-visible {
          outline: none;
        }

        @media screen and (max-width: 500px) {
          padding: 0.5rem;
        }
      }

      select {
        width: 100%;
        padding: 8px 11px;
        height: auto;
        border: none;
        border-bottom: 1px solid #cecece;
      }

      .customborderTop {
        border-top: 1px solid black;
        padding-top: 0.5rem;
      }

      .ant-space {
        display: flex;
        justify-content: space-between;

        .ant-space-item {
          width: 100%;
          position: relative;

          &:nth-child(2) {
            margin-left: 1rem;
          }

          .ant-form-item {
            .ant-row {
              width: 100%;
            }
          }

          .removeBtnWrapper {
            position: relative;
            padding: 0.5rem 0;

            .removeBtn {
              border: none;
              box-shadow: none;
              color: #eb2a2e;
              position: absolute;
              background: transparent;
              right: 0rem;
              bottom: 0.5rem;
              font-weight: 700;
              font-size: 14px;
            }
          }
        }
      }

      .buttonSection {
        display: flex;
        justify-content: center;
        align-items: center;

        .btn {
          background: #eb2a2e;
          border-radius: 25px;
          padding: 0.5rem 2rem;
          border: none;
          color: white;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
            rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
          height: auto;

          @media screen and (max-width: 500px) {
            padding: 0.3rem 1.2rem;
          }
        }
      }

      .maxImageInfo {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 1rem;

        span {
          padding-right: 0.5rem;
          color: #eb2a2e;
        }
      }

      .passwordWrapper {
        display: flex;
        flex-direction: column;
        position: relative;

        .editPassword {
          position: absolute;
          right: 10px;
          top: 1.5rem;
        }
      }
    }

    .AddCarBtn {
      margin-bottom: 5px;

      .ant-row {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            display: flex;
            justify-content: flex-end;

            .ant-btn {
              border: none;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              width: auto;
              background: #eb2a2e;
              color: white;
              border-radius: 25px;
            }
          }
        }
      }
    }
  }

  .viewProfile {
    .content-row {
      display: flex;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid #cecece;
      padding: 0.8rem 0;

      svg {
        font-size: 22px;
        margin-right: 1rem;
        color: #eb2a2e;

        @media screen and (max-width: 500px) {
          font-size: 18px;
        }
      }

      h4 {
        margin: 0;
        font-size: 18px;
        font-weight: 500;
        padding-top: 0.3rem;
        display: flex;
        align-items: center;

        @media screen and (max-width: 500px) {
          font-size: 14px;
        }
      }

      .editPassword {
        position: absolute;
        right: 0;
      }
    }

    .editRow {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1.5rem;

      button {
        background: #eb2a2e;
        color: white;
        border-radius: 25px;
        border: none;
      }
    }
  }
}

.carCard {
  display: flex;
  justify-content: flex-start !important;
  text-align: left;
  width: 100%;
  padding: 0.5rem 1.5rem;
  flex-direction: column;

  .formHeader {
    display: flex;
    align-items: center;

    h4 {
      margin-bottom: 0;
      font-size: 16px;
      display: flex;
    }

    svg {
      display: flex;
      align-items: baseline;
      font-size: 18px;
      margin-right: 1rem;
    }
  }

  .carForm {
    .formlabel {
      font-size: 20px;
      font-weight: 500;

      @media screen and (max-width: 500px) {
        font-size: 15px;
      }
    }

    .infoMsg {
      p {
        display: flex;
        align-items: center;
        margin: 0.5rem 0;

        @media only screen and (max-width: 767px) {
          align-items: baseline;
        }

        svg {
          margin-right: 0.5rem;
          color: #eb2a2e;
        }
      }
    }

    .ant-form-item {
      margin-bottom: 10px;

      .ant-input {
        padding: 1rem;

        @media screen and (max-width: 500px) {
          padding: 0.8rem 0.8rem;
        }
      }

      .ant-select-selector {
        padding: 0.6rem 0.8rem;
        height: auto;
      }
    }

    .splitRow {
      display: flex;
      justify-content: space-between;
    }

    .customborderTop {
      border-top: 1px solid black;
      padding-top: 0.5rem;
    }

    .ant-space {
      display: flex;
      justify-content: space-between;

      .ant-space-item {
        width: 100%;
        position: relative;

        &:nth-child(2) {
          margin-left: 1rem;
        }

        .ant-form-item {
          .ant-row {
            width: 100%;
          }
        }

        .removeBtnWrapper {
          position: relative;
          padding: 0.5rem 0;

          .removeBtn {
            border: none;
            box-shadow: none;
            color: #eb2a2e;
            position: absolute;
            background: transparent;
            right: 0rem;
            bottom: 0.5rem;
            font-weight: 700;
            font-size: 14px;
          }
        }
      }
    }

    .buttonSection {
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        background: #eb2a2e;
        border-radius: 25px;
        padding: 0.5rem 2rem;
        height: auto;

        @media screen and (max-width: 500px) {
          padding: 0.3rem 1.2rem;
        }
      }
    }

    .AddCarBtn {
      margin-bottom: 5px;

      .ant-row {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            display: flex;
            justify-content: flex-end;

            .ant-btn {
              border: none;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              width: auto;
              background: #eb2a2e;
              color: white;
              border-radius: 25px;
            }
          }
        }
      }
    }
  }
}

.existingCar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: none;
  border-radius: 8px;
  border: none;

  .ant-card-body {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
    margin: 0 0.6rem;

    .cardHeader {
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid #cecece;
      padding: 0rem 0.5rem 0 0.5rem;

      h4 {
        font-size: 16px;
      }

      svg {
        font-size: 16px;
      }

      .CarsButtons {
        display: flex;
        align-items: center;
        margin-top: -10px;

        button {
          border: none;
        }

        .deleteIcon {
          color: #eb2a2e;
        }
      }
    }

    .cardBody {
      padding-top: 0.6rem;

      label {
        font-size: 14px;
        color: darkgray;
        font-weight: 500;
      }

      h4 {
        font-size: 16px;
      }
    }
  }
}

.addCarSection {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.8rem;
  margin: 0;

  button {
    background: #eb2a2e;
    color: white;
    border: none;
    border-radius: 25px;
  }
}

.editPwdModal {
  .ant-modal-body {
    .editPasswordForm {
      padding: 0px 4px;

      .modalHeader {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-bottom: 0.6rem;

        h4 {
          font-size: 24px;

          @media screen and (max-width: 500px) {
            font-size: 20px;
          }
        }

        button {
          position: absolute;
          right: -35px;
          top: -45px;
          padding: 0.5rem 0.9rem;
          border-radius: 10px;
          border: none;
          background: #eb2a2e;
          color: #fff;
          font-size: 20px;
          height: auto;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

          @media screen and (max-width: 450px) {
            right: -38px;
          }

          &:hover {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            transform: translateY(-0.15em);
          }
        }
      }

      .inputLabel {
        color: #000;
        font-weight: 500;
        padding-bottom: 0.5rem;
        font-size: 18px;
        width: 100%;

        @media screen and (max-width: 500px) {
          font-size: 14px;
          padding-bottom: 0.3rem;
        }

        .star {
          color: red;
          padding-left: 0.2rem;
        }
      }

      .ant-form-item {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .ant-input-affix-wrapper {
              border: none;
              border-bottom: 1px solid #cecece !important;
              border-radius: 0 !important;

              &:focus-visible {
                outline: none;
                box-shadow: none;
              }

              &:focus {
                outline: none;
                box-shadow: none;
              }
            }

            .ant-input {
              padding: 0.6rem 0;

              &:focus-visible {
                outline: none;
              }

              &:focus {
                box-shadow: none;
              }
            }

            .ant-input-suffix {
              align-items: center;

              .anticon {
                margin-top: 5px;
              }
            }
          }
        }
      }

      .ant-input-suffix {
        align-items: center;

        .anticon {
          margin-top: 5px;
        }
      }

      .buttonSection {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 2rem;
        align-items: center;

        button {
          background: #eb2a2e;
          color: white;
          height: auto;
          border-radius: 25px;
        }
      }
    }
  }
}