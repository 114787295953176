.DetailsForm {
  width: 600px !important;

  @media screen and (max-width: 900px) {
    margin: 10rem auto;
  }

  @media screen and (max-width: 560px) {
    margin: 0rem auto;
    top: 40px;
  }

  @media screen and (min-width: 340px) and (max-width: 600px) {
    margin: 0rem auto;
    top: 25px;
  }

  .ant-modal-content {
    padding: 0;
    min-height: 450px;

    .ant-modal-body {
      .detailSection {
        padding: 20px 24px;

        .modalHeader {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          h4 {
            font-size: 24px;

            @media screen and (max-width: 460px) {
              font-size: 16px;
            }
          }

          .CloseBtn {
            position: absolute;
            right: -40px;
            top: -35px;
            padding: 0.5rem 0.9rem;
            border-radius: 10px;
            border: none;
            background: #eb2a2e;
            color: #fff;
            font-size: 20px;
            height: auto;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

            @media screen and (max-width: 500px) {
              font-size: 18px;
              right: -35px;
              padding: 0.2rem 0.7rem;
            }
          }
        }

        .inputLabel {
          color: #000;
          font-weight: 500;
          padding-bottom: 0.5rem;
          font-size: 18px;
          width: 100%;

          @media screen and (max-width: 500px) {
            font-size: 14px;
            padding-bottom: 0.3rem;
          }

          .star {
            color: red;
            padding-left: 0.2rem;
          }
        }

        .splitRow {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-top: 2rem;

          @media screen and (max-width: 460px) {
            margin-top: 0.2rem;
          }

          .ant-col {
            @media screen and (max-width: 500px) {
              margin-top: 0.8rem;
            }
          }

          .customSelect {
            border: none;
            border-bottom: 1px solid #cecece;
            border-radius: 0;
            padding: 0rem 0rem 0.375rem 0rem;

            &:focus {
              box-shadow: none;
            }

            option {
              font-size: 14px;
            }
          }

          .preffixNumber {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.18rem;
            border-bottom: 1px solid #cecece;
            border-right: 1px solid #cecece;

            // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            @media screen and (max-width: 500px) {
              padding: 0.2rem;
            }
          }

          input {
            padding: 0.2rem 0;
            border: none;
            border-bottom: 1px solid #cecece;
            border-radius: 0;
            width: 100%;

            &:focus-visible {
              outline: none;
            }

            &:focus {
              outline: none;
            }
          }

          .mobileWrapper {
            padding-left: 0.5rem;
          }
        }

        .wholeRow {
          width: 100%;
          margin-top: 2rem;

          @media screen and (max-width: 460px) {
            margin-top: 0.2rem;
          }

          .ant-col {
            @media screen and (max-width: 500px) {
              margin-top: 0.8rem;
            }
          }

          input {
            width: 100%;
            border: none;
            border-bottom: 1px solid #cecece;

            &:focus {
              outline: none;
            }

            &:active {
              outline: none;
            }
          }
        }

        .buttonSection {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 1.5rem 0 0.5rem 0;

          .paynowBtn {
            background: #eb2a2e;
            border-radius: 25px;
            padding: 0.5rem 2rem;
            height: auto;
            color: white;
            border: none;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

            &:hover {
              box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
              transform: translateY(-0.15em);
            }
          }
        }

        .checkbox {
          font-size: 18px;

          .optional {
            color: #a2a2a2;
            font-size: 14px;
            padding-left: 0.3rem;
          }

          @media screen and (max-width: 1366px) {
            font-size: 14px;
          }
        }

        .loaderWrapper {
          display: flex;
          justify-content: flex-end;
          position: relative;

          .ant-spin {
            position: absolute;
            top: -3.2rem;
            right: 1rem;

            @media screen and (max-width: 500px) {
              top: -2.8rem;
            }
          }
        }
      }
    }
  }
}

.paymentWrapper {
  .backBtnSection {
    position: absolute;
    top: 0.5rem;
    z-index: 999;
    color: white;
    left: 0.5rem;

    svg {
      font-size: 30px;
      font-weight: 700;
    }
  }

  .leftSection {
    background: #eb2a2e;
    padding: 5rem 2rem;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 7px 0 0 7px;

    @media screen and (max-width: 500px) {
      padding: 3rem 2rem;
    }

    .Amount {
      width: 100%;

      .label {
        color: #fff;
        font-size: 16px;
        font-weight: 700;
      }

      input {
        border: none;
        border-radius: 5px;
        padding: 0.5rem;
        height: auto;
        width: 100%;
        margin: 0.5rem 0;

        &:focus-visible {
          outline: none;
          box-shadow: none;
        }
      }

      .tipInfo {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 1rem;

        @media screen and (max-width: 500px) {
          padding-bottom: 0.5rem;
        }

        .anticon {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-right: 0.3rem;
          color: white;
          padding-bottom: 0.2rem;

          @media screen and (max-width: 500px) {
            padding-bottom: 0.3rem;
          }
        }

        h4 {
          color: white;
          font-size: 10px;
          display: flex;
          align-items: center;
        }
      }

      .ant-form-item-explain-error {
        color: white !important;
      }
    }

    .Tip {
      width: 100%;

      .label {
        color: #fff;
        font-size: 16px;
        font-weight: 700;
      }

      input {
        border: none;
        border-radius: 5px;
        padding: 0.5rem;
        height: auto;
        width: 100%;
        margin: 0.5rem 0;

        &:focus-visible {
          outline: none;
          box-shadow: none;
        }
      }

      .tipInfo {
        display: flex;
        align-items: center;
        padding-bottom: 1rem;
        text-align: left;

        @media screen and (max-width: 500px) {
          padding-bottom: 0.5rem;
        }

        .anticon {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-right: 0.3rem;
          color: white;
          padding-bottom: 0.4rem;

          @media screen and (max-width: 500px) {
            padding-bottom: 0.3rem;
          }
        }

        h4 {
          color: white;
          font-size: 10px;
          display: flex;
          align-items: center;
        }
      }
    }

    .Message {
      width: 100%;

      .customTextarea {
        resize: none;
        width: 100%;
        border: none;
        border-radius: 5px;
        padding: 0.3rem;
      }
    }
  }

  .NoTip {
    flex-direction: row;

    .Amount {
      width: 100%;
    }
  }

  .rightSection {
    padding: 0rem;

    @media screen and (max-width: 500px) {
      padding: 1rem 1.9rem;
    }

    .buttonSection {
      padding: 2rem 1rem;

      button {
        display: flex;
        height: auto;
        width: 100%;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        &:last-child {
          margin-top: 0.5rem;
        }

        &:active {
          border-color: #eb2a2e;
        }

        &:focus-visible {
          outline: none;
        }

        img {
          width: 50px;
        }
      }

      .google-pay-button-container {
        button {
          height: 10px !important;
          width: 100% !important;
          align-items: center;
          justify-content: center;
        }
      }

      .google-pay-button-container {
        display: block !important;
      }
    }

    .OrSection {
      position: relative;

      span {
        position: absolute;
        z-index: 999;
        top: 5px;
        left: 43%;
        background: white;
        padding: 0 0.6rem;
      }
    }

    .StripeHeader {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .stripeFormDetails {
      padding: 0 1rem;
    }

    .totalAmountRow {
      .totalAmountSection {
        display: flex;
        border: 1px solid #cecece;
        height: 2.57rem;
        width: 100%;
        border-radius: 8px;
        padding: 0.4rem;
        align-items: center;
        color: #cecece;
      }

      .totalAmountSection {
        color: black;
      }

      .infoCol {
        display: flex;
        align-items: center;
        padding: 0.5rem 0 0 0.3rem;
        color: #eb2a2e;

        svg {
          margin-right: 0.5rem;
        }
      }
    }

    .bottomBtnContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.5rem;

      .paymentBtn {
        background: #eb2a2e;
        border: none;
        border-radius: 25px;
        width: 10rem;
      }
    }

    .StripeForm {
      padding: 0.4rem 1rem;
    }

    .CardDetailsHeader {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h4 {
      @media screen and (max-width: 500px) {
        font-size: 16px;
      }
    }

    .CardSection {
      .centerAligned {
        display: flex;
        justify-content: space-between;

        input {
          padding: 0.6rem;
          width: 100%;
          border: none;
          border-bottom: 1px solid #cecece;
          box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
          border-radius: 5px;

          &:focus-visible {
            outline: none;
          }
        }
      }
    }

    .bottomBtnSection {
      .payNow {
        background: #eb2a2e;
        color: white;
        border-radius: 25px;
        padding: 0.5rem 1rem;
        height: auto;
        border: none;
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        &:hover {
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          transform: translateY(-0.15em);
        }
      }
    }
  }

  .closeBtnSection {
    position: relative;

    button {
      position: absolute;
      right: -10px;
      top: -15px;
      padding: 0.5rem 0.9rem;
      border-radius: 10px;
      border: none;
      background: #eb2a2e;
      color: #fff;
      font-size: 20px;
      height: auto;
      z-index: 9999;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

      @media screen and (max-width: 460px) {
        font-size: 18px;
        right: -10px;
        padding: 0.2rem 0.7rem;
      }

      @media screen and (max-width: 330px) {
        font-size: 18px;
        right: -6px;
        padding: 0.2rem 0.7rem;
      }

      &:hover {
        color: #fff;
        border: none;
      }
    }
  }
}

.passwordModal {
  .ant-modal-content {
    margin: 8rem auto;

    @media screen and (max-width: 1024px) {
      margin: 10rem auto;
    }

    @media screen and (max-width: 1024px) {
      margin: 10rem auto;
    }

    @media screen and (max-width: 500px) {
      margin: 3rem 1rem;
    }

    .ant-modal-body {
      .modalHeader {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-bottom: 1rem;
        // margin-bottom: 2rem;
        // border-bottom: 1px solid #cecece;

        h4 {
          font-size: 24px;

          @media screen and (max-width: 500px) {
            font-size: 16px;
          }
        }

        button {
          position: absolute;
          right: -40px;
          top: -35px;
          padding: 0.5rem 0.9rem;
          border-radius: 10px;
          border: none;
          background: #eb2a2e;
          color: #fff;
          font-size: 20px;
          height: auto;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

          &:hover {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            transform: translateY(-0.15em);
          }
        }
      }

      .alreadyExistText {
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          text-align: center;

          @media screen and (max-width: 550px) {
            text-align: center;
          }
        }
      }

        .LoginbtnWrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 1rem 0;
          a {
            .login-with-google-btn {
              transition: background-color .3s, box-shadow .3s;

              padding: 12px 16px 12px 42px;
              border: none;
              border-radius: 3px;
              box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
              color: #757575;
              font-size: 14px;
              font-weight: 500;
              font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
              background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
              background-color: white;
              background-repeat: no-repeat;
              background-position: 12px 14.5px;

              &:hover {
                box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
              }

              &:active {
                background-color: #eeeeee;
              }

              &:focus {
                outline: none;
                box-shadow:
                  0 -1px 0 rgba(0, 0, 0, .04),
                  0 2px 4px rgba(0, 0, 0, .25),
                  0 0 0 3px #c8dafc;
              }

            }
          }
        }
      .inputLabel {
        color: #000;
        font-weight: 500;
        font-size: 18px;
        padding: 0.5rem 0 0rem 0;
        z-index: 99999;
        position: relative;
      }

      .ant-form-item {
        margin-top: -10px;
      }

      .buttonSection {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2.5rem;

        .changePwdBtn {
          background: #eb2a2e;
          border-radius: 25px;
          padding: 0.5rem 2rem;
          height: auto;
        }
      }

      .confirmPwdWrapper {
        margin-top: 2.5rem;
      }

      .ant-form-item {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .ant-input-affix-wrapper {
              border: none;
              border-bottom: 1px solid #cecece !important;
              border-radius: 0 !important;

              &:focus-visible {
                outline: none;
                box-shadow: none;
              }

              &:focus {
                outline: none;
                box-shadow: none;
              }
            }

            .ant-input {
              padding: 0.6rem 0;

              &:focus-visible {
                outline: none;
              }

              &:focus {
                box-shadow: none;
              }
            }

            .ant-input-suffix {
              align-items: center;

              .anticon {
                margin-top: 5px;
              }
            }
          }
        }
      }

      .forgotSection {
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          border: none;
          color: #eb2a2e;
        }
      }
    }
  }
}

.CarModal {
  width: 650px !important;
  top: 50px;

  @media screen and (max-width: 1366px) {
    top: 30px;
  }

  @media screen and (max-width: 1024px) {
    margin: 8rem auto;
  }

  @media screen and (max-width: 500px) {
    margin: 1rem auto;
  }

  .ant-modal-content {
    padding: 0;

    .ant-modal-body {
      .carDetailsForm {
        padding: 20px 24px;

        .modalHeader {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          .backIcon {
            position: absolute;
            left: 0;
            font-size: 25px;
            color: black;
            cursor: pointer;

            @media screen and (max-width: 330px) {
              font-size: 18px;
            }

            @media screen and (min-width: 340px) and (max-width: 400px) {
              font-size: 22px;
            }
          }

          h4 {
            font-size: 24px;

            @media screen and (max-width: 500px) {
              font-size: 18px;
              margin-bottom: 0;
            }

            @media screen and (min-width: 340px) and (max-width: 400px) {
              font-size: 16px;
              margin-bottom: 0;
            }

            @media screen and (max-width: 330px) {
              font-size: 13px;
              margin-bottom: 0;
            }
          }

          button {
            position: absolute;
            right: -40px;
            top: -35px;
            padding: 0.5rem 0.9rem;
            border-radius: 10px;
            border: none;
            background: #eb2a2e;
            color: #fff;
            font-size: 20px;
            height: auto;

            &:hover {
              transform: translateY(-0.15em);
            }

            @media screen and (max-width: 500px) {
              font-size: 18px;
              right: -30px;
              padding: 0.2rem 0.7rem;
            }
          }
        }

        .inputLabel {
          color: #000;
          font-weight: 500;
          padding-bottom: 0.5rem;
          font-size: 16px;
          width: 100%;
          margin-top: 0.5rem;
        }



        .customSelect {
          border: none;
          margin-top: 0.4rem;
          border-bottom: 1px solid #cecece;
          border-radius: 0;
          padding: 0rem 0rem 0.375rem 0rem;
          font-size: 14px;

          &:focus {
            box-shadow: none;
          }

          option {
            font-size: 14px;
          }
        }

        .infoMsg {
          p {
            display: flex;
            align-items: center;
            margin: 0.5rem 0;

            @media only screen and (max-width: 767px) {
              align-items: baseline;
            }

            svg {
              margin-right: 0.5rem;
              color: #eb2a2e;
            }
          }
        }

        .splitRow {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: end;

          input {
            padding: 0.2rem 0;
            border: none;
            border-bottom: 1px solid #cecece;
            border-radius: 0;
            width: 100%;
            font-size: 14px;

            &:focus-visible {
              outline: none;
            }

            &:focus {
              outline: none;
              box-shadow: none;
            }
          }

          select {
            width: 100%;
            padding: 0.5rem 0;
            border: 0;
            border-bottom: 1px solid #cecece;

            &:focus-visible {
              outline: none;
            }

            &:active {
              outline: none;
            }
          }

          .inputLabel {
            color: #000;
            font-weight: 500;
            padding-bottom: 0.5rem;
            font-size: 16px;
            width: 100%;
            margin-top: 0.7rem;

            @media screen and (max-width: 500px) {
              font-size: 14px;
              padding-bottom: 0rem;
            }

            .star {
              color: red;
              padding-left: 0.2rem;
            }
          }

          .customSelect {
            border: none;
            border-bottom: 1px solid #cecece;
            border-radius: 0;
            padding: 0.4rem 2.25rem 0.375rem 0rem;
            font-size: 14px;

            &:focus {
              box-shadow: none;
            }

            option {
              font-size: 14px;
            }
          }

          .infoMsg {
            margin: 0.6rem 0;
            display: flex;
            align-items: center;

            p {
              margin-bottom: 0;
              display: flex;
              align-items: center;

              span {
                margin-right: 0.4rem;
                color: #eb2a2e;
              }
            }
          }
        }

        .ant-space {
          flex-direction: column;
          border-top: 1px solid black;

          .ant-space-item {
            width: 100%;
          }

          .removeSetion {
            display: flex;
            justify-content: flex-end;

            .RemoveCarBtn {
              border: none;
              color: #eb2a2e;
              font-weight: 500;
              margin: 0;
              display: flex;
              align-items: center;
            }
          }
        }

        .addCarSection {
          .ant-row {
            .ant-col {
              .ant-form-item-control-input {
                .ant-form-item-control-input-content {
                  button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #eb2a2e;
                    color: white;
                    vertical-align: middle;
                    font-weight: 500;
                    border: none;
                  }
                }
              }
            }
          }
        }

        .buttonSectionLeft {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin: 0.5rem 0 1rem 0;
          padding-right: 1rem;

          @media only screen and (max-width: 768px) {
            padding-right: 0.6rem;
          }

          .paynowBtn {
            background: #eb2a2e;
            border-radius: 25px;
            padding: 0.5rem 2rem;
            height: auto;
            border: none;
            color: white;

            &:hover {
              box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
              transform: translateY(-0.25em);
            }
          }
        }

        .buttonSectionRight {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin: 0.5rem 0 1rem 0;
          padding-left: 1rem;

          @media only screen and (max-width: 768px) {
            padding-left: 0.2rem;
          }

          .paynowBtn {
            background: #eb2a2e;
            border-radius: 25px;
            padding: 0.5rem 2rem;
            height: auto;
            border: none;
            color: white;

            &:hover {
              box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
              transform: translateY(-0.25em);
            }
          }
        }

        .middleButton {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0.5rem 0 1rem 0;
          padding-right: 1rem;

          @media only screen and (max-width: 768px) {
            padding-right: 0.6rem;
          }

          .paynowBtn {
            background: #eb2a2e;
            border-radius: 25px;
            padding: 0.5rem 2rem;
            height: auto;
            border: none;
            color: white;

            @media only screen and (max-width: 768px) {
               padding: 0.5rem 0.8rem;
            }
            &:hover {
              box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
              transform: translateY(-0.25em);

            }
          }
        }

        .bottomSection {
          margin-top: 1rem;

          .ant-form-item {
            margin-bottom: 10px;
          }
        }

        .checkbox {
          font-size: 18px;

          .optional {
            color: #a2a2a2;
            font-size: 14px;
            padding-left: 0.3rem;
          }

          @media screen and (max-width: 1366px) {
            font-size: 14px;
          }
        }

        .btn-box {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-top: 1rem;
          padding: 0.5rem 0;

          .addBtn {
            background: #eb2a2e;
            color: white;
            border: none;
            border-radius: 25px;
            padding: 0.3rem 2rem;

            @media screen and (max-width: 330px) {
              padding: 0.5rem 0rem;
              height: auto;
              font-size: 12px;
              width: 150px;
            }

            &:hover {
              box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
              transform: translateY(-0.15em);
            }
          }

          .removeBtn {
            background: white;
            color: #eb2a2e;
            border: none;
            border-radius: 25px;
            padding: 0.3rem 1rem;
            border: 1px solid #eb2a2e;

            @media screen and (max-width: 330px) {
              padding: 0.5rem 0rem;
              height: auto;
              font-size: 12px;
              width: 80px;
            }

            &:hover {
              box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
              transform: translateY(-0.15em);
            }
          }
        }
      }
    }
  }
}

.summaryModal {
  width: 700px !important;
  top: 50px;

  @media screen and (max-width: 1366px) {
    top: 30px;
  }

  @media screen and (max-width: 1024px) {
    margin: 4rem auto;
  }

  @media screen and (max-width: 460px) {
    margin: 1rem auto;
  }

  .ant-modal-content {
    padding: 0;

    .ant-modal-body {
      padding: 20px 24px;

      .modalHeader {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-bottom: 1px solid #cecece;
        padding-bottom: 0.6rem;

        .backIcon {
          position: absolute;
          left: 0;
          font-size: 25px;
          color: black;
          cursor: pointer;
        }

        h4 {
          font-size: 24px;

          @media screen and (max-width: 500px) {
            font-size: 20px;
          }
        }

        button {
          position: absolute;
          right: -40px;
          top: -35px;
          padding: 0.5rem 0.9rem;
          border-radius: 10px;
          border: none;
          background: #eb2a2e;
          color: #fff;
          font-size: 20px;
          height: auto;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

          @media screen and (max-width: 450px) {
            right: -30px;
            padding: 0.2rem 0.7rem;
          }

          &:hover {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            transform: translateY(-0.15em);
          }
        }
      }

      .ant-tabs {
        margin: 2rem 0 0 0;

        .ant-tabs-nav {
          &::before {
            border-bottom: none;
          }

          .ant-tabs-nav-wrap {
            padding: 0 1rem;

            .ant-tabs-nav-list {
              width: 100%;
              display: flex;
              justify-content: space-around;

              .ant-tabs-tab {
                padding: 0.6rem 0rem;
                font-size: 14px;

                &:hover {
                  color: #eb2e2e !important;
                }

                @media screen and (min-width: 768px) {
                  width: 33%;
                  text-align: center;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 18px;
                  font-weight: 500;
                }
              }

              .ant-tabs-tab-active {
                @media screen and (min-width: 768px) {
                  padding: 0.5rem 3rem;
                  background: #eb2e2e;
                  color: white !important;
                  border-radius: 25px;
                }

                .ant-tabs-tab-btn {
                  color: #eb2e2e;

                  @media screen and (min-width: 768px) {
                    color: white;
                  }
                }
              }

              .ant-tabs-ink-bar {
                height: 2px;
                background: red !important;

                @media screen and (min-width: 768px) {
                  height: 0px;
                }
              }
            }
          }
        }
      }

      .personalInfo {
        padding: 1rem 0;
        border-bottom: 1px solid #cecece;
        text-align: center;

        @media only screen and (max-width: 767px) {
          text-align: left;
        }

        .align-items-center {
          display: flex;
          // justify-content: center;
          // align-items: center;
          flex-direction: column;
        }

        h3 {
          font-size: 18px;
          color: black;
          padding-bottom: 0.5rem;
          text-align: center;
          text-decoration: underline;
        }

        h4 {
          font-size: 18px;
          color: gray;

          @media screen and (max-width:767px) {
            font-size: 15px;
          }
        }

        p {
          font-size: 18px;
          color: black;
          margin-bottom: 10px;

          @media screen and (max-width:767px) {
            font-size: 15px;
          }
        }
      }

      .carInfo {
        padding: 1rem 0;
        border-bottom: 1px solid #cecece;
        text-align: center;

        @media only screen and (max-width: 767px) {
          text-align: left;
        }

        .car-Card {
          padding: 0;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          border-radius: 8px;
          margin-bottom: 1.5rem;

          .ant-card-body {
            padding: 0;

            .center-aligned {
              display: flex;
              padding: 1rem 1rem 0 0.6rem;
              align-items: center;
              justify-content: space-around;

              h4 {
                font-size: 18px;
                color: black;
                text-align: center;
                font-weight: 700;
                margin-bottom: 0;
                margin-top: 0;

                @media screen and (max-width:767px) {
                  font-size: 15px;
                }
              }
            }

            .card-content {
              padding: 0.5rem 1rem;

              .NoVehicleSection {
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .align-items-center {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
              }

              h3 {
                font-size: 18px;
                color: black;
                padding-bottom: 0.5rem;
                text-align: center;
                text-decoration: underline;
              }

              h4 {
                font-size: 18px;
                color: gray;
                text-align: center;

                @media screen and (max-width:767px) {
                  font-size: 15px;
                }
              }

              p {
                font-size: 18px;
                color: black;
                margin-bottom: 10px;
                text-align: center;
                font-weight: 700;

                @media screen and (max-width:767px) {
                  font-size: 15px;
                }
              }
            }
          }
        }

        .serviceSection {
          margin: 1rem 0.5rem 0.2rem 0.1rem;

          h4 {
            font-size: 18px;
            font-weight: 700;

            span {
              font-weight: 300;
            }
          }
        }
      }

      .createAccountCheckbox {
        margin: 1rem auto;
        display: flex;
        background: white;
        z-index: 999999999999;
        flex-direction: row;

        .leftCol {
          padding-left: 0;
          padding-right: 0;
        }

        .rightCol {
          padding-left: 0;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .anticon {
            padding-left: 0rem;
            color: #eb2a2e;
          }
        }
      }

      .termsCheckbox {
        display: flex;
        .ant-checkbox-wrapper {
          // display: flex;
          justify-content: flex-start;
          align-items: baseline;

          margin-right: 0.5rem;

        }

        .redText {
          color: red;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .modalFooter {
        margin: 1rem 0;

        .summaryBtnSection {
          display: flex;
          justify-content: center;
          align-items: center;

          .edit {
            margin-right: 1rem;
            width: 90px;
            border: none;
            border-radius: 25px;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            font-size: 16px;
            height: auto;
            color: #eb2a2e;

            &:hover {
              box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
              transform: translateY(-0.15em);
            }
          }

          .confirm {
            background: #eb2a2e;
            border: none;
            width: 90px;
            color: white;
            border-radius: 25px;
            font-size: 16px;
            height: auto;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

            &:hover {
              box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
              transform: translateY(-0.15em);
            }
          }

          .disabledConfirmBtn {
            border: none;
            background: #cecece;
            color: white;
            border-radius: 25px;
            height: auto;
            font-size: 16px;
            cursor: not-allowed;
          }
        }
      }

      .serviceDropdown {
        margin: 1rem 0;

        .customSelect {
          border: none;
          border-bottom: 1px solid #cecece;
          border-radius: 0;
          padding: 0rem 0rem 0.375rem 0rem;

          &:focus {
            box-shadow: none;
          }

          option {
            font-size: 14px;
          }
        }

        .infoMsg {
          p {
            display: flex;
            align-items: center;
            margin: 0.5rem 0;

            @media only screen and (max-width: 767px) {
              align-items: baseline;
            }

            svg {
              margin-right: 0.5rem;
              color: #eb2a2e;
            }
          }
        }

      }
    }
  }
}

.privacyModal {
  .ant-modal-content {
    padding: 0;

    .ant-modal-body {
      padding: 20px 24px;

      .modalHeader {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-bottom: 1px solid #cecece;
        padding-bottom: 0.6rem;

        .backIcon {
          position: absolute;
          left: 0;
          font-size: 25px;
          color: black;
          cursor: pointer;
        }

        h4 {
          font-size: 24px;

          @media screen and (max-width: 500px) {
            font-size: 20px;
          }
        }

        button {
          position: absolute;
          right: -40px;
          top: -35px;
          padding: 0.5rem 0.9rem;
          border-radius: 10px;
          border: none;
          background: #eb2a2e;
          color: #fff;
          font-size: 20px;
          height: auto;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

          @media screen and (max-width: 450px) {
            right: -30px;
          }

          &:hover {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            transform: translateY(-0.15em);
          }
        }
      }

      .privacy-body {
        .ant-collapse {
          box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
          border-radius: 0;

          .ant-collapse-item {
            border-radius: 0;

            &:last-child {
              .ant-collapse-header {
                border-radius: 0;
              }
            }

            .ant-collapse-header {
              background: #eb2a2e;
              color: white;
            }

            .ant-collapse-expand-icon {
              color: white;
              display: flex;
              align-items: flex-end;

              svg {
                font-size: 18px;
              }
            }
          }
        }

        .newPage {
          margin: 1rem 0;
          display: flex;
          justify-content: flex-end;

          a {
            color: #eb2a2e;
          }
        }
      }

      .infoText {
        text-align: center;
      }

      .modalFooter {
        margin: 2rem 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .acceptBtn {
          background: #eb2a2e;
          border-radius: 25px;
          padding: 0.4rem 2rem;
          height: auto;
          color: white;
        }

        .customBtn {
          margin-right: 1rem;
        }
      }
    }
  }
}

.existingCustomerModel {
  .ant-modal-content {
    padding: 0;

    .ant-modal-body {
      padding: 20px 24px;

      .modalHeader {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-bottom: 1px solid #cecece;
        padding-bottom: 0.6rem;

        .backIcon {
          position: absolute;
          left: 0;
          font-size: 25px;
          color: black;
          cursor: pointer;
        }

        h4 {
          font-size: 24px;

          @media screen and (max-width: 500px) {
            font-size: 20px;
          }
        }

        button {
          position: absolute;
          right: -40px;
          top: -35px;
          padding: 0.5rem 0.9rem;
          border-radius: 10px;
          border: none;
          background: #eb2a2e;
          color: #fff;
          font-size: 20px;
          height: auto;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

          @media screen and (max-width: 450px) {
            right: -30px;
          }

          &:hover {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            transform: translateY(-0.15em);
          }
        }
      }

      .modal-body {
        padding: 2rem 0;

        h4 {
          text-align: center;
        }

        .modalButtonSection {
          display: flex;
          justify-content: center;
          padding: 1.5rem 0;

          button {
            min-width: 150px;
            padding: 0.5rem 0;
            height: auto;
            background: #eb2a2e;
            color: white;
            border-radius: 25px;
            outline: none;

            &:last-child {
              background: transparent;
              color: #eb2a2e;
              border: none;
              box-shadow: none;
            }

            &:hover {
              outline: none;
              border-color: transparent;
            }

            &:active {
              border-color: transparent;
              box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            }
          }
        }
      }

    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.paymentModal {
  width: 700px !important;
}

.star {
  color: red;
  padding-left: 0.2rem;
}