.NotFoundWrapper {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  background-size: cover;
  background: url("http://kenzieclean.ae/wp-content/uploads/2019/07/kenzie-car-wash-detailing.jpg");

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9;
  }

  .disabledQRSection {
    text-align: center;
    margin: 8rem auto;

    @media screen and (max-width: 769px) {
      margin: 3rem auto;
    }

    position: relative;
    border-radius: 0;
    z-index: 9999999;

    .linkSection {
      color: white;
      margin-bottom: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        padding-left: 1rem;
        color: white;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .ant-card {
      background: #f0f8ff;

      @media screen and (max-width: 769px) {
        margin: 2.5rem;
      }

      @media screen and (max-width: 350px) {
        margin: 1.5rem;
      }

      .ant-card-body {
        padding: 2rem;
        font-size: 30px;
        border-radius: 0;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
          rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
          rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

        h2 {
          font-size: 60px;

          @media screen and (max-width: 769px) {
            font-size: 30px;
          }

          @media screen and (max-width: 350px) {
            font-size: 25px;
          }
        }

        p {
          @media screen and (max-width: 769px) {
            font-size: 15px;
          }

          @media screen and (max-width: 350px) {
            font-size: 12px;
          }
        }

        .imgWrapper {
          width: 100%;
          height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;

          .ant-image {
            width: 150px;

            @media screen and (max-width: 769px) {
              width: 120px;
            }
          }
        }
      }
    }
  }
}