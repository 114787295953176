.LoginFormWrapper {
  padding: 1rem 4rem 0.8rem 4rem;
  width: 100%;
  height: auto;

  @media screen and (max-width: 1366px) {
    height: auto;
  }

  @media screen and (min-width: 810px) and (max-width: 1024px) {
    padding: 1rem 3rem 1rem 3rem;
  }

  @media screen and (max-width: 800px) {
    padding: 1rem 3rem 0 3rem;
    height: 42vh;
  }

  .ant-form {
    .ant-row {
      display: flex;
      justify-content: center;

      .ant-form-item-control-input {
        .ant-form-item-control-input-content {
          .ant-input {
            padding: 0.6rem 0;
            border: none;
            border-bottom: 1px solid #cecece;
            border-radius: 0;
          }
        }
      }
    }

    .inputLabel {
      padding: 0.4rem 0 0 0;
      font-size: 16px;
      font-weight: 500;
      text-transform: capitalize;
    }

    .buttonSection {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;

      button {
        background: #eb2a2e;
        padding: 0.4rem 1.8rem;
        height: auto;
        border-radius: 25px;
        font-size: 16px;

        &:hover {
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          transform: translateY(-0.25em);
        }
      }
    }

    .forgotPwdSection {
      .ant-col {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        a {
          color: #eb2a2e;
          font-size: 16px;
        }
      }
    }

    .registerSection {
      .ant-col {
        display: flex;
        justify-content: center;
        align-items: center;

        .LoginbtnWrapper {
          a {
            .login-with-google-btn {
              transition: background-color .3s, box-shadow .3s;

              padding: 12px 16px 12px 42px;
              border: none;
              border-radius: 3px;
              box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
              color: #757575;
              font-size: 14px;
              font-weight: 500;
              font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
              background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
              background-color: white;
              background-repeat: no-repeat;
              background-position: 12px 14.5px;

              &:hover {
                box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
              }

              &:active {
                background-color: #eeeeee;
              }

              &:focus {
                outline: none;
                box-shadow:
                  0 -1px 0 rgba(0, 0, 0, .04),
                  0 2px 4px rgba(0, 0, 0, .25),
                  0 0 0 3px #c8dafc;
              }

            }
          }
        }


        .registerText {
          font-size: 16px;
          margin-top: 1rem;
          font-weight: 700;
          display: flex;

          a {
            font-size: 30px;
          }

          p {
            color: #eb2a2e;
            font-size: 16px;
            font-weight: 700;
            text-decoration: underline;
            margin-left: 0.4rem;
            cursor: pointer;
          }
        }
      }
    }

    .passwordFormItem {
      margin: 1rem 0 1rem 0;

      .passwordInput {
        padding: 0.02rem 0rem 0.02rem 0rem;
        border: none;
        border-radius: 0;

        .ant-input-suffix {
          border-bottom: 1px solid #cecece;
          margin-inline-start: 0;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.swal-modal {
  .swal-footer {
    display: flex;
    justify-content: center;
    align-items: center;

    .swal-button {
      background: #eb2a2e;
      color: white;
      border-radius: 25px;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}

.privacyModal {
  .registerSection {
    .ant-col {
      display: flex;
      justify-content: center;
      align-items: center;

      .noramlRegisteration {
        margin: 2rem 0 0.8rem 0;

        button {
          padding: 0.6rem 2.25rem;
          height: auto;
          font-size: 14px;
          font-weight: 700;
          border: none;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

          &:hover {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            outline: none;
            color: #000;
            border: transparent;
          }
        }
      }

      .LoginbtnWrapper {
        padding-bottom: 1.2rem;

        a {
          .login-with-google-btn {
            transition: background-color .3s, box-shadow .3s;

            padding: 12px 16px 12px 42px;
            border: none;
            border-radius: 3px;
            box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
            color: #757575;
            font-size: 14px;
            font-weight: 500;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
            background-color: white;
            background-repeat: no-repeat;
            background-position: 12px 14.5px;

            &:hover {
              box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
            }

            &:active {
              background-color: #eeeeee;
            }

            &:focus {
              outline: none;
              box-shadow:
                0 -1px 0 rgba(0, 0, 0, .04),
                0 2px 4px rgba(0, 0, 0, .25),
                0 0 0 3px #c8dafc;
            }

          }
        }
      }


      .registerText {
        font-size: 16px;
        margin-top: 1rem;
        font-weight: 700;
        display: flex;

        a {
          font-size: 30px;
        }

        p {
          color: #eb2a2e;
          font-size: 16px;
          font-weight: 700;
          text-decoration: underline;
          margin-left: 0.4rem;
          cursor: pointer;
        }
      }

    }
  }
}

.reverifymodal {
  .ant-modal-content {
    padding: 0;

    .ant-modal-body {
      padding: 20px 24px;

      .modalHeader {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-bottom: 1px solid #cecece;
        padding-bottom: 0.6rem;

        .backIcon {
          position: absolute;
          left: 0;
          font-size: 25px;
          color: black;
          cursor: pointer;
        }

        h4 {
          font-size: 25px;

          @media screen and (max-width: 500px) {
            font-size: 24;
          }
        }

        button {
          position: absolute;
          right: -40px;
          top: -35px;
          padding: 0.5rem 0.9rem;
          border-radius: 10px;
          border: none;
          background: #eb2a2e;
          color: #fff;
          font-size: 20px;
          height: auto;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

          @media screen and (max-width: 450px) {
            right: -30px;
          }

          &:hover {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            transform: translateY(-0.15em);
          }
        }
      }

      .modalBody {
        padding: 2rem 0;

        .errorMessage {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }

        .registerSection {
          padding: 1rem 0;

          .noramlRegisteration {
            display: flex;
            justify-content: center;
            align-items: center;

            button {
              background: #eb2a2e;
              color: white;
              min-width: 150px;
              padding: 0.4rem 0.6rem;
              height: auto;
              border-radius: 25px;
              box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
              margin-right: 1rem;

              &:hover {
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                transform: translateY(-0.15em);
                outline: none;
                border: transparent;
              }

              @media screen and (max-width:375px) {
                min-width: auto;
                padding: 0.2rem 0.4rem;
              }
            }
          }
        }
      }
    }
  }
}