.termsWrapper {
  margin: 2rem auto;
  .modalHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    h4 {
      font-weight: 700;
      font-size: 24px;
    }
  }
  .privacy-body {
    .ant-collapse {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      border-radius: 0;
      .ant-collapse-item {
        border-radius: 0;
        &:last-child {
          .ant-collapse-header {
            border-radius: 0;
          }
        }
        .ant-collapse-header {
          background: #eb2a2e;
          color: white;
        }
        .ant-collapse-expand-icon {
          color: white;
          display: flex;
          align-items: flex-end;
          svg {
            font-size: 18px;
          }
        }
      }
    }
  }
}
