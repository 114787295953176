.mainWrapper {
    height: 100%;
    min-height: 100vh;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    background-size: cover;

    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        // background: rgba(0, 0, 0, 0.6);
        z-index: 9;
    }

    .dashboardWrapper {
        z-index: 999;
        position: absolute;
        top: 0;
        justify-content: space-between;
        width: 100%;
        right: 0;
        padding: 2rem;

        @media screen and (max-width: 480px) {
            right: 0rem;
            padding: 0.8rem 0.6rem;
        }

        @media screen and (max-width: 350px) {
            right: 0rem;
            padding: 0.5rem 0.1rem;
        }

        @media screen and (min-width: 1300px) and (max-height:768px) {
            margin-bottom: 3rem;
        }

        .ant-col {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: baseline;

            .btnsWrapper {
                @media screen and (max-width: 350px) {
                    white-space: nowrap;
                }

                .dashboard {
                    position: relative;
                    border: none;
                    background: #eb4343;
                    color:#020202;
                    font-size: 15px;
                    height: auto;
                    border-radius: 25px;
                    font-weight: 700;
                    padding: 0.6rem 2rem;

                    @media screen and (max-width: 500px) {
                        font-size: 12px;
                        padding: 0.3rem 0.6rem;
                    }

                    @media screen and (max-width: 350px) {
                        font-size: 12px;
                        padding: 0.2rem 0.7rem;
                    }

                    &:hover {
                        color: white;
                    }

                    &:focus-visible {
                        outline: none;
                    }
                }

                .logOutBtn {
                    margin-left: 0.8rem;
                }
            }

            h4 {
                color: #000;
                font-size: 24px;

                @media screen and (max-width: 500px) {
                    font-size: 12px;
                }
            }
        }
    }

    .SignInWrapper {
        position: absolute;
        top: 1rem;
        right: 2rem;
        z-index: 9;

        @media screen and (max-width: 500px) {
            right: 0.7rem;
        }

        button {
            position: relative;
            border: none;
            background: transparent;
            color: #020202;
            font-size: 20px;

            @media screen and (min-width:380px) and (max-width: 500px) {
                font-size: 18px;
            }

            @media screen and (max-width: 380px) {
                font-size: 15px;
            }

            &:hover {
                color: white;
            }

            &:focus-visible {
                outline: none;
            }
        }

        .signIn {
            background: #eb4343;
            height: auto;
            border-radius: 25px;
            padding: 0.2rem 1.6rem;

            @media screen and (max-width: 500px) {
                padding: 0.2rem 1rem;

            }
        }

        .register {
            border-radius: 0;
            margin-right: 1rem;
            height: auto;

            @media screen and (max-width: 500px) {
                margin-right: 0.1rem;
            }

            &:focus-visible {
                outline: none;
                border: none;
            }
        }
    }



    @media screen and (max-width: 1366px) {
        background-position-x: center;
        background-size: cover;
    }

    .mainSection {
        margin-top: 7rem !important;

        @media screen and (max-width: 500px) {
            margin-top: 3rem !important;
        }

        .linkSection {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            z-index: 99;
            padding-bottom: 3.75rem;
            font-size: 18px;
            z-index: 999;

            svg {
                color: #000 !important;
                margin-right: 0.8rem;
            }

            a {
                text-decoration: none;

                button {
                    // background: #eb2a2e;
                    background: #eb4343;
                    color: #020202;
                    border-radius: 50px;
                    width: 300px;
                    padding: 0.6rem 0.3rem;
                    height: auto;
                    font-size: 16px;
                    margin-right: 1.25rem;
                    border: none;
                    text-transform: uppercase;
                    font-weight: 700;
                    align-items: center;
                    display: flex;
                    justify-content: center;

                    &:focus-visible {
                        outline: none;
                    }

                    &:active {
                        background: #eb2a2e;
                        color: #fff;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    &:hover {
                        border: none;
                        color: #fff;
                    }

                    @media screen and (max-width: 1366px) {
                        margin-right: 0;
                        margin-top: 1rem;
                        padding: 0.5rem 0.3rem;
                        font-size: 24px;
                        width: 280px;
                    }

                    @media screen and (max-width: 380px) {
                        padding: 0.6rem 0.2rem;
                        font-size: 16px;
                        width: 200px;
                    }

                    @media screen and (max-width: 300px) {
                        padding: 0.4rem 1rem;
                        width: 220px;
                        font-size: 12px;
                    }

                    @media screen and (min-width: 380px) and (max-width: 500px) {
                        padding: 0.7rem 0.2rem;
                        width: 230px;
                        font-size: 16.5px;
                    }
                }
            }

            @media screen and (max-width: 1366px) {
                padding-bottom: 1.7rem;
            }

            @media screen and (max-width: 500px) {
                padding-bottom: 0.8rem;
                font-size: 14px;
                margin-bottom: 2rem;
            }
        }

        .logoSection {
            z-index: 999;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 3.25rem;

            @media screen and (max-width: 1366px) {
                padding-bottom: 2rem;
            }

            @media screen and (min-width: 1500px) {
                padding-bottom: 1rem;
            }

            .logoImage {
                width: 10rem;

                @media screen and (max-width: 380px) {
                    width: 6rem !important;
                }

                @media screen and (max-width: 1366px) {
                    width: 8rem;
                }

                @media screen and (min-width: 380px) and (max-width: 500px) {
                    width: 6rem;
                }
            }

            @media screen and (max-width: 1366px) {
                padding-bottom: 2.5rem;
            }

            @media screen and (max-width: 500px) {
                padding-bottom: 1rem;
            }

            @media screen and (max-width: 380px) {
                padding-bottom: 0.4rem;
            }
        }

        .welcomeTextSection {
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 999;
            padding-bottom: 2rem;
            position: relative;

            h4 {
                color: #fff;
                font-size: 24px;

                @media screen and (max-width: 380px) {
                    font-size: 18px !important;
                    padding-bottom: 0rem;
                    margin-bottom: 0;
                }

                @media screen and (max-width: 1366px) {
                    font-size: 22px;
                    padding-bottom: 0rem;
                    margin-top: 1rem;
                }
            }

            @media screen and (max-width: 1366px) {
                padding-bottom: 1.125rem;
            }

            @media screen and (max-width: 550px) {
                padding-bottom: 0rem;
            }

            @media screen and (max-width: 380px) {
                padding-bottom: 0.4rem;
            }
        }

        .buttonSection {
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 999;
            position: relative;
            text-align: center;
            padding-bottom: 3rem;

            @media screen and (max-width: 1100px) {
                padding-bottom: 2rem;
                flex-direction: column;
            }

            @media screen and (max-width: 380px) {
                padding-bottom: 0.7rem;
                flex-direction: column;
            }

            button {
                background: #eb4343;
                color: #020202;
                border-radius: 50px;
                width: 300px;
                padding: 0.6rem 1rem;
                height: auto;
                font-size: 16px;
                margin-right: 1.25rem;
                border: none;
                text-transform: uppercase;
                font-weight: 700;

                &:focus-visible {
                    outline: none;
                }

                &:active {
                    background: #eb2a2e;
                    color: #fff;
                }

                &:last-child {
                    margin-right: 0;
                }

                &:hover {
                    border: none;
                    color: #fff;
                }

                @media screen and (max-width: 550px) {
                    margin-top: 3vh;
                    margin-right: 0;

                }

                @media screen and (min-width:600px) and (max-width: 1200px) {
                    margin-right: 0;
                    margin-top: 1rem;
                    padding: 0.5rem 0.7rem;
                    font-size: 14px;
                    width: 280px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                @media screen and (max-width: 380px) {
                    padding: 0.6rem 0.6rem;
                    font-size: 16px;
                    width: 210px;
                }

                @media screen and (max-width: 300px) {
                    padding: 0.4rem 1rem;
                    width: 210px;
                    font-size: 12px;
                }

                @media screen and (min-width: 380px) and (max-width: 500px) {
                    padding: 0.8rem 1rem;
                    width: 230px;
                    font-size: 17.5px;
                }
            }
        }

        .buildingSection {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 999;
            padding-bottom: 1.875rem;

            @media screen and (max-width: 400px) {
                padding-bottom: 1rem;
                margin-top: 0.5rem;
            }

            @media screen and (max-width: 380px) {
                padding-bottom: 0.4rem;
            }

            .buildingImage {
                width: 100px;
                max-height: 100px;

                @media screen and (max-width: 1366px) {
                    width: 80px;
                }

                @media screen and (max-width: 350px) {
                    width: 50px !important;
                }

                @media screen and (max-width: 500px) {
                    width: 60px !important;
                }

                @media screen and (min-width: 1500px) {
                    width: 50px !important;
                }
            }
        }

        .addressSection {
            display: flex;
            justify-content: center;
            width: 100%;
            z-index: 99;
            position: relative;

            @media screen and (max-width: 400px) {
                align-items: center;
            }

            svg {
                color: #000;
                font-size: 20px;
                margin-right: 0.5rem;
                margin-top: 0.1rem;

                @media screen and (max-width: 400px) {
                    margin-top: 0.3rem;
                }

                @media screen and (max-width: 300px) {
                    font-size: 16px;
                    margin-right: 0.2rem;
                    margin-top: 0.8rem;
                }
            }

            h4 {
                color: #000;
                font-size: 16px;

                @media screen and (max-width: 400px) {
                    margin-right: 0;
                    margin-top: 0.8rem;
                }

                @media screen and (max-width: 300px) {
                    font-size: 12px;
                }
            }
        }
    }
}

.newQrModal {
    .ant-modal-content {
        padding: 0;

        .ant-modal-body {
            padding: 20px 24px;

            .modalHeader {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                border-bottom: 1px solid #cecece;
                padding-bottom: 0.6rem;

                .backIcon {
                    position: absolute;
                    left: 0;
                    font-size: 25px;
                    color: black;
                    cursor: pointer;
                }

                h4 {
                    font-size: 24px;

                    @media screen and (max-width: 500px) {
                        font-size: 20px;
                    }
                }

                button {
                    position: absolute;
                    right: -40px;
                    top: -35px;
                    padding: 0.5rem 0.9rem;
                    border-radius: 10px;
                    border: none;
                    background: #eb2a2e;
                    color: #fff;
                    font-size: 20px;
                    height: auto;
                    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

                    @media screen and (max-width: 450px) {
                        right: -30px;
                    }

                    &:hover {
                        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                        transform: translateY(-0.15em);
                    }
                }
            }

            .modal-body {
                padding-top: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;

                h4 {
                    font-size: 20px;
                }

            }


            .modalFooter {
                margin: 2rem 0;
                display: flex;
                justify-content: center;
                align-items: center;

                .profileBtn {
                    background: #eb2a2e;
                    border-radius: 25px;
                    padding: 0.4rem 2rem;
                    height: auto;
                    color: white;
                }
            }
        }
    }
}

#capture {
    width: 100%;
    padding: 2rem;
    margin-bottom: 2rem;

    @media only screen and (max-width: 767px) {
        padding: 0.5rem;
    }

    .invoiceModal {
        width: 100%;
        color: #000 !important;
    }

    .largeText {
        margin-bottom: 4px;
        font-size: 15px;
        line-height: 29px;
        color: #000;
        font-weight: 600;
        font-family: 'montserrat', sans-serif;

    }

    @media only screen and (max-width: 767px) {
        .largeText {
            font-size: 15px;
            line-height: 26px;
        }
    }

    .invoiceLogo {
        width: 200px;
    }

    @media only screen and (max-width: 767px) {
        .invoiceLogo {
            width: 100px;
        }
    }

    .w_70 {
        width: 70%;
    }

    .w_50 {
        width: 50%;
    }

    @media only screen and (max-width: 767px) {
        .sm_wdth_6 {
            width: 60%;
        }

        .sm_wdth_4 {
            width: 40%;
        }
    }

    .invoiceTitle {
        font-size: 28px;
        color: #202e5a;
        font-weight: 500;
    }

    @media only screen and (max-width: 767px) {
        .invoiceTitle {
            font-size: 24px;
        }

        .sm_wdth_10 {
            width: auto;
        }
    }

    .w-40 {
        width: 44%;
    }
}


.noDataModel {
    .ant-modal-content {
        padding: 0;

        .ant-modal-body {
            padding: 20px 24px;

            .modalHeader {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                border-bottom: 1px solid #cecece;
                padding-bottom: 0.6rem;

                .backIcon {
                    position: absolute;
                    left: 0;
                    font-size: 25px;
                    color: black;
                    cursor: pointer;
                }

                h4 {
                    font-size: 24px;

                    @media screen and (max-width: 500px) {
                        font-size: 20px;
                    }
                }

                button {
                    position: absolute;
                    right: -40px;
                    top: -35px;
                    padding: 0.5rem 0.9rem;
                    border-radius: 10px;
                    border: none;
                    background: #eb2a2e;
                    color: #fff;
                    font-size: 20px;
                    height: auto;
                    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

                    @media screen and (max-width: 450px) {
                        right: -30px;
                    }

                    &:hover {
                        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                        transform: translateY(-0.15em);
                    }
                }
            }

            .modal-body {
                padding-top: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                flex-direction: column;

                .icon {
                    margin: 1rem 0;

                    svg {
                        font-size: 60px;
                    }
                }

                h4 {
                    font-size: 25px;
                }

            }
        }
    }
}

.blockedModal {
    .ant-modal-content {
        padding: 0;

        .ant-modal-body {
            padding: 20px 24px;

            .modalHeader {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                border-bottom: 1px solid #cecece;
                padding-bottom: 0.6rem;

                .backIcon {
                    position: absolute;
                    left: 0;
                    font-size: 25px;
                    color: black;
                    cursor: pointer;
                }

                h4 {
                    font-size: 24px;

                    @media screen and (max-width: 500px) {
                        font-size: 20px;
                    }
                }

                button {
                    position: absolute;
                    right: -40px;
                    top: -35px;
                    padding: 0.5rem 0.9rem;
                    border-radius: 10px;
                    border: none;
                    background: #eb2a2e;
                    color: #fff;
                    font-size: 20px;
                    height: auto;
                    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

                    @media screen and (max-width: 450px) {
                        right: -30px;
                    }

                    &:hover {
                        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                        transform: translateY(-0.15em);
                    }
                }
            }

            .modalBody {
                padding-top: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                flex-direction: column;

                p {
                    font-size: 18px;
                    text-align: center;

                }

                .profileButton {
                    background: #eb2a2e;
                    color: white;
                    padding: 0.5rem 2rem;
                    height: auto;
                    border-radius: 25px;
                }
            }
        }
    }
}