.MenuModal {
  margin: 0rem auto;

  @media screen and (max-width: 380px) {
    margin: 0rem auto;
  }

  @media screen and (min-width: 820px) {
    width: 600px !important;
  }

  @media screen and (min-width: 450px) and (max-width: 820px) {
    width: 600px !important;
  }

  @media screen and (max-width: 550px) {
    margin: -3rem auto;
  }

  .ant-modal-content {
    padding-bottom: 2.5rem;
  }

  .modalHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    h2 {
      @media screen and (max-width: 1366px) {
        font-size: 24px;
      }

      @media screen and (max-width: 300px) {
        font-size: 18px;
      }

      @media screen and (max-width: 500px) {
        font-size: 22px;
      }
    }

    button {
      position: absolute;
      right: -20px;
      top: -17px;
      padding: 0.5rem 0.9rem;
      border-radius: 10px;
      border: none;
      background: #eb2a2e;
      color: #fff;
      font-size: 20px;
      height: auto;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

      @media screen and (max-width: 500px) {
        right: -10px;
        padding: 0.2rem 0.7rem;
      }

      &:hover {
        color: #fff;
        border: none;
      }
    }
  }

  .carousalWrapper {
    padding: 1rem;

    @media screen and (max-width: 500px) {
      padding: 0 0.5rem;
    }

    .NoServiceWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 4rem auto;

      h5 {
        font-size: 26px;
      }
    }

    .carousel {
      object-fit: contain;

      .carousel-inner {
        .carousel-item {
          .carousel-caption {
            color: #000;
            background: white;
            width: 100%;
            right: 0;
            left: 0;
            position: relative;
            bottom: 0;
            padding-bottom: 0;
          }

          img {
            // min-height: 40vh;
            // height: 100%;
            // max-height: 40vh;
            // object-fit: contain;
            height: 595px;
            width: 420px;

            @media screen and (min-width:401px) and (max-width:500px) {
              height: 495px;
              width: 320px;
            }

            @media screen and (max-width:400px) {
              height: 400px;
              width: 300px;
            }

            @media screen and (min-width:800px) and (max-width:1050px) {
              height: 795px;
              width: 620px;
            }
          }
        }
      }

      .carousel-control-prev {
        left: -50px;

        span {
          background-color: #eb2a2e;
          border-radius: 5px;
          background-size: 20px;

          @media screen and (max-width: 500px) {
            width: 1.6rem;
            height: 1.6rem;
          }
        }

        @media screen and (max-width: 500px) {
          left: -36px;
        }
      }

      .carousel-control-next {
        right: -50px;

        span {
          background-color: #eb2a2e;
          border-radius: 5px;
          background-size: 20px;

          @media screen and (max-width: 500px) {
            width: 1.6rem;
            height: 1.6rem;
          }
        }

        @media screen and (max-width: 500px) {
          right: -36px;
        }
      }

      .carousel-indicators {
        bottom: -2.5rem;

        button {
          border-radius: 25px;
          border-top: 0;
          border-bottom: 0;
          background-color: #000;
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}