.summaryModalNEW {
  .modalHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #cecece;
    padding-bottom: 0.6rem;

    .backIcon {
      position: absolute;
      left: 0;
      font-size: 25px;
      color: black;
      cursor: pointer;
    }

    h4 {
      font-size: 24px;
      font-size: 20px;
      padding: 0.5rem 0;
      margin-bottom: 0;

      @media screen and (max-width: 500px) {
        font-size: 20px;

      }
    }

    button {
      position: absolute;
      right: -20px;
      top: -25px;
      padding: 0.5rem 0.9rem;
      border-radius: 10px;
      border: none;
      background: #eb2a2e;
      color: #fff;
      font-size: 20px;
      height: auto;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

      @media screen and (max-width: 500px) {
        right: -5px;
        padding: 0.2rem 0.7rem;
        top: -20px;
      }

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        transform: translateY(-0.15em);
        color: white;
      }
    }
  }

  .ant-tabs {
    margin: 2rem 0 0 0;

    .ant-tabs-nav {
      &::before {
        border-bottom: none;
      }

      .ant-tabs-nav-wrap {
        padding: 0 1rem;

        .ant-tabs-nav-list {
          width: 100%;
          display: flex;
          justify-content: space-around;

          .ant-tabs-tab {
            padding: 0.6rem 0rem;
            font-size: 14px;

            &:hover {
              color: #eb2e2e !important;
            }

            @media screen and (min-width: 768px) {
              width: 33%;
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 18px;
              font-weight: 500;
            }
          }

          .ant-tabs-tab-active {
            @media screen and (min-width: 768px) {
              padding: 0.5rem 3rem;
              background: #eb2e2e;
              color: white !important;
              border-radius: 25px;
            }

            .ant-tabs-tab-btn {
              color: #eb2e2e;

              @media screen and (min-width: 768px) {
                color: white;
              }
            }
          }

          .ant-tabs-ink-bar {
            height: 2px;
            background: red !important;

            @media screen and (min-width: 768px) {
              height: 0px;
            }
          }
        }
      }
    }
  }

  .personalInfo {
    padding: 1rem;
    border-bottom: 1px solid #cecece;
    text-align: center;

    @media only screen and (max-width: 768px) {
      text-align: left;
    }

    .align-items-center {
      display: flex;
      // justify-content: center;
      // align-items: center;
      flex-direction: column;
    }

    h3 {
      font-size: 18px;
      color: black;
      padding-bottom: 0.5rem;
      text-align: center;
      text-decoration: underline;
    }

    h4 {
      font-size: 18px;
      color: gray;
    }

    p {
      font-size: 18px;
      color: black;
      margin-bottom: 10px;
    }
  }

  .carInfo {
    padding: 1rem;
    border-bottom: 1px solid #cecece;
    text-align: center;

    @media only screen and (max-width: 768px) {
      text-align: left;
    }

    .car-Card {
      padding: 0;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      border-radius: 8px;
      margin-bottom: 1.5rem;

      .ant-card-body {
        padding: 0;

        .center-aligned {
          display: flex;
          padding: 1rem 1rem 0 0.6rem;
          align-items: center;
          justify-content: space-around;

          h4 {
            font-size: 18px;
            color: black;
            text-align: center;
            font-weight: 700;
            margin-bottom: 0;
            margin-top: 0;
          }
        }

        .card-content {
          padding: 0.5rem 1rem;

          .NoVehicleSection {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .align-items-center {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }



          h3 {
            font-size: 18px;
            color: black;
            padding-bottom: 0.5rem;
            text-align: center;
            text-decoration: underline;

          }

          h4 {
            font-size: 18px;
            color: gray;
            text-align: center;
          }

          p {
            font-size: 18px;
            color: black;
            margin-bottom: 10px;
            text-align: center;
            font-weight: 700;
          }
        }
      }
    }

    .ServiceSection {
      margin: 1rem 0.5rem 0.2rem 0.1rem;

      h4 {
        font-size: 18px;
        font-weight: 700;

        span {
          font-weight: 300;
        }
      }
    }
  }

  .createAccountCheckbox {
    margin: 1rem auto;
    display: flex;
    background: white;
    z-index: 999999999999;
    flex-direction: row;

    .leftCol {
      padding-left: 0;
      padding-right: 0;
    }

    .rightCol {
      padding-left: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .anticon {
        padding-left: 0rem;
        color: #eb2a2e;
      }
    }
  }

  .termsCheckboxx {
    .ant-checkbox-wrapper {
      justify-content: flex-start;
      align-items: baseline;
      padding: 0 1rem;
    }

    .redText {
      color: red;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

    }
  }

  .modalFooter {
    margin: 1rem 0;

    .summaryBtnSection {
      display: flex;
      justify-content: center;
      align-items: center;

      .edit {
        margin-right: 1rem;
        width: 90px;
        border: none;
        border-radius: 25px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        font-size: 16px;
        height: auto;
        color: #eb2a2e;

        &:hover {
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          transform: translateY(-0.15em);
        }
      }

      .confirm {
        background: #eb2a2e;
        border: none;
        width: 90px;
        color: white;
        border-radius: 25px;
        font-size: 16px;
        height: auto;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        &:hover {
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          transform: translateY(-0.15em);

        }
      }

      .disabledConfirmBtn {
        border: none;
        background: #cecece;
        color: white;
        border-radius: 25px;
        height: auto;
        font-size: 16px;
        cursor: not-allowed;
      }

      .addVehicle {
        margin-right: 1rem;
        width: 120px;
        border: none;
        border-radius: 25px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        font-size: 16px;
        height: auto;
        color: #fff;
        background: #eb2a2e;

        &:hover {
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          transform: translateY(-0.15em);
        }
      }
    }
  }

  .dropdownWrapper {
    margin: 1rem 1.5rem 0 1rem;

    .customSelect {
      border: none;
      border-bottom: 1px solid #cecece;
      border-radius: 0;
      padding: 0rem 0rem 0.375rem 0rem;

      &:focus {
        box-shadow: none;
      }

      option {
        font-size: 14px;
      }
    }

    .infoMsg {
      p {
        display: flex;
        align-items: center;
        margin: 0.5rem 0;

        @media only screen and (max-width: 767px) {
          align-items: baseline;
        }

        svg {
          margin-right: 0.5rem;
          color: #eb2a2e;
        }
      }
    }
  }
}