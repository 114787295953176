.tabletLoginWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../Images/LoginBackground.jpg");
  background-size: cover;
  height: 100vh;

  @media screen and (max-width: 1024px) {
    background-position-x: 50%;
  }

  .loginCard {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin: 8rem auto;
    width: 650px;

    @media screen and (max-width: 1024px) {
      margin: 6rem auto;
    }

    @media screen and (max-width: 450px) {
      width: auto;
    }

    .modalHeader {
      display: flex;
      justify-content: flex-end;

      button {
        cursor: pointer;
        position: absolute;
        right: -15px;
        top: -25px;
        padding: 0.5rem 0.9rem;
        border-radius: 10px;
        border: none;
        background: #eb2a2e;
        color: #fff;
        font-size: 20px;
        height: auto;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        @media screen and (max-width: 500px) {
          font-size: 18px;
          right: -35px;
          padding: 0.2rem 0.7rem;
        }
      }
    }

    .ant-card-body {
      border-radius: 0;
      padding: 0;

      .logoSection {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.5rem;
      }

      .ImageColumn {
        .ant-image {
          height: 100%;

          .ant-image-img {
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}

.reverifymodal {
  .ant-modal-content {
    padding: 0;

    .ant-modal-body {
      padding: 20px 24px;

      .modalHeader {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-bottom: 1px solid #cecece;
        padding-bottom: 0.6rem;

        .backIcon {
          position: absolute;
          left: 0;
          font-size: 25px;
          color: black;
          cursor: pointer;
        }

        h4 {
          font-size: 24px;

          @media screen and (max-width: 500px) {
            font-size: 20px;
          }
        }

        button {
          position: absolute;
          right: -40px;
          top: -35px;
          padding: 0.5rem 0.9rem;
          border-radius: 10px;
          border: none;
          background: #eb2a2e;
          color: #fff;
          font-size: 20px;
          height: auto;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

          @media screen and (max-width: 450px) {
            right: -30px;
          }

          &:hover {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            transform: translateY(-0.15em);
          }
        }
      }

      .modal-body {
        padding: 2rem 0;

      }
    }
  }
}

.googleReminderModal {
  .ant-modal-content {
    padding: 0;

    .ant-modal-body {
      padding: 20px 24px;

      .modalHeader {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-bottom: 1px solid #cecece;
        padding-bottom: 0.6rem;

        .backIcon {
          position: absolute;
          left: 0;
          font-size: 25px;
          color: black;
          cursor: pointer;
        }

        h4 {
          font-size: 24px;

          @media screen and (max-width: 500px) {
            font-size: 20px;
          }
        }

        button {
          position: absolute;
          right: -40px;
          top: -35px;
          padding: 0.5rem 0.9rem;
          border-radius: 10px;
          border: none;
          background: #eb2a2e;
          color: #fff;
          font-size: 20px;
          height: auto;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

          @media screen and (max-width: 450px) {
            right: -30px;
          }

          &:hover {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            transform: translateY(-0.15em);
          }
        }
      }

      .modalBody {
        padding: 2rem 0;

        .LoginbtnWrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 1rem 0;

          a {
            .login-with-google-btn {
              transition: background-color .3s, box-shadow .3s;

              padding: 12px 16px 12px 42px;
              border: none;
              border-radius: 3px;
              box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
              color: #757575;
              font-size: 14px;
              font-weight: 500;
              font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
              background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
              background-color: white;
              background-repeat: no-repeat;
              background-position: 12px 14.5px;

              &:hover {
                box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
              }

              &:active {
                background-color: #eeeeee;
              }

              &:focus {
                outline: none;
                box-shadow:
                  0 -1px 0 rgba(0, 0, 0, .04),
                  0 2px 4px rgba(0, 0, 0, .25),
                  0 0 0 3px #c8dafc;
              }

            }
          }
        }

        p {
          width: 100%;
          text-align: center;
          font-size: 16px;
        }

        .changeBtnWrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          vertical-align: middle;


        }
      }
    }
  }
}