* {
  font-family: "montserrat", sans-serif;
}

.swal-text {
  text-align: center;
}

@media screen and (max-width: 450px) {
  .swal-modal {
    width: calc(100% - 60px);
  }

  .ant-dropdown-menu {
    margin-left: 1rem !important;
  }
}

.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

.ant-spin-dot-item {
  background-color: #eb2a2e !important;
}

.reminderCheckboxWrapper {
  padding: 0.5rem 1.5rem;
}

.ReminderToggleWrapper {
  border-bottom: 1px solid #cecece;
  padding: 1rem 0;
}

.paymentWrapperComponent .reminderCheckboxWrapper {
  padding: 0.5rem 0.2rem;
}