.customerDashboardWrapper {
  padding: 1rem 2rem;
  margin-top: 7rem;
  .ant-row {
    display: flex;
    justify-content: space-between;
    .ant-card {
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      margin: 1rem 0;
      .ant-card-body {
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        svg {
          font-size: 30px;
          margin-bottom: 1rem;
        }
        h2 {
          margin-bottom: 0;
        }
        h4 {
          margin-bottom: 0;
          margin-top: 0.4rem;
          font-size: 16px;
        }
      }
    }
    .tableCard {
      .ant-card-body {
        align-items: normal !important;
        padding: 0;
        h4 {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 2rem;
        }
        .tableWrapper {
          .ant-table-wrapper {
            .ant-table {
              .ant-table-container {
                .ant-table-content {
                  overflow: hidden;
                  overflow-x: scroll;
                  @media screen and (max-width: 500px) {
                    overflow: hidden;
                    overflow-x: scroll;
                  }
                  .ant-table-thead {
                    .ant-table-cell {
                      text-align: center;
                      color: #67748e;

                      @media screen and (max-width: 500px) {
                        font-size: 12px;
                        white-space: nowrap;
                      }
                    }
                  }
                  .ant-table-tbody {
                    .ant-table-cell {
                      text-align: center;
                      color: #67748e;

                      @media screen and (max-width: 500px) {
                        font-size: 12px;
                        white-space: nowrap;
                      }

                      .viewDetailsButton {
                        background: #eb2a2e;
                        color: white;
                        border: none;
                        border-radius: 25px;
                        padding: 4px 15px;

                        @media screen and (max-width: 500px) {
                          font-size: 14px;
                          padding: 0.1rem 0.8rem;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .buttonWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 0;
      button {
        background: #eb2a2e;
        color: white;
        border: none;
        border-radius: 25px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
    }
  }
}
