.forgotPwdWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../Images/LoginBackground.jpg");
  background-size: cover;
  height: 100%;
  min-height: 100vh;

  @media screen and (max-width: 500px) {
    background-position-x: 50%;
  }

  @media screen and (max-width: 900px) {
    background-position-x: 50%;
  }

  .forgotCard {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin: 8rem auto;
    width: 700px;

    @media screen and (max-width: 1024px) {
      width: 600px;
      margin: 3rem auto;
    }

    @media screen and (max-width: 768px) {
      width: 600px;
      margin: 3rem auto;
    }

    @media screen and (max-width: 500px) {
      width: auto;
      margin: 3rem auto;
    }

    .ant-card-body {
      padding: 0;

      .logoSection {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem 0 0 0;

        @media screen and (max-width: 400px) {
          padding: 0.5rem;
        }
      }

      .ImageColumn {
        .ant-image {
          height: 100%;

          .ant-image-img {
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .ant-form {
        padding: 1rem 3rem 0rem 3rem;

        @media screen and (max-width: 400px) {
          padding: 0.5rem 3rem 0rem 3rem;
        }

        label {
          padding: 0.5rem 0;
          font-size: 15px;
        }

        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .ant-input {
              padding: 0.6rem 0.2rem;
              border: none;
              // border-bottom: 1px solid #cecece;
              border-radius: 0;
              width: 100%;
            }

            .forgotInput {
              border-bottom: 1px solid #cecece;
            }

            .ant-input-password {
              padding: 0.2rem 0;
              border: none;
              border-bottom: 1px solid #cecece;
              border-radius: 0;
              width: 100%;
            }
          }
        }

        .buttonSection {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1rem 1rem 0 1rem;

          button {
            background: #eb2a2e;
            padding: 0.4rem 1.8rem;
            height: auto;
            border-radius: 25px;

            &:hover {
              box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
              transform: translateY(-0.25em);
            }
          }
        }
      }

      .header {
        display: flex;
        flex-direction: column;
        padding: 1rem 2rem;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 400px) {
          padding: 0.2rem 3rem;
          text-align: left;
          justify-content: flex-start;
        }
      }

      .resetPwdHeader {
        @media screen and (max-width: 400px) {
          padding: 0.2rem 3rem;
          text-align: center;
          justify-content: flex-start;
        }
      }

      .backLogin {
        display: flex;
        justify-content: center;
        padding: 2rem;
        align-items: center;

        a {
          font-size: 14px;
          font-weight: 700;
          color: #eb2a2e;
          text-decoration: none;
        }

        svg {
          margin-right: 0.5rem;
          font-size: 18px;
          font-weight: 700;
          color: #eb2a2e;
        }
      }
    }
  }
}